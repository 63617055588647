import React, { SVGProps } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './MyAccountDetail.module.scss';
import { ChevronClose } from '../icons';
import { Button, ButtonTypes } from '../common/Button';
import { dateUtil } from '../../utils/dateUtil';
import { getDecimalVal } from '../../utils/numberUtil';

type DetailsProps = {
	cardActivated: boolean | any;
	details: Array<{
		icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
		text: string;
		textDetail?: string;
		amount?: string;
		amountDetail?: string;
		button?: string;
		buttonHandler?: () => void;
		link?: string;
	}>;
};

export const MyAccountDetail = ({ details, cardActivated }: DetailsProps) => {
	const navigate = useNavigate();
	return (
		<div className="d-flex flex-column">
			<div className={`${classes['title-row']} `}>
				<p
					className={`text-h5-350 d-flex align-items-center ${
						!cardActivated ? classes['text-yellow'] : ''
					}`}
				>
					My Accounts
				</p>
				<p
					className={`text-sm-325 d-flex align-items-center text-primary ${
						!cardActivated ? classes['text-white'] : ''
					}`}
				>
					As of {dateUtil.getFormattedDate(new Date(), 'ddd MMMM DD hh:mm A')}
				</p>
			</div>
			{details.map((detail, index) => {
				return (
					<div
						key={`activity-detail-${index}`}
						className={`${classes['detail-item']} d-flex flex-row justify-content-between p-md-32 p-16 align-items-center`}
						onClick={() => {
							if (detail?.link && !detail.button) {
								navigate(detail.link);
							} else if (detail.button && detail.link && detail?.buttonHandler) {
								detail.buttonHandler();
							} else if (detail.button && detail.link) {
								navigate(detail.link);
							}
						}}
					>
						<div className="d-flex flex-row">
							<div className="d-flex align-items-center pr-16">
								<detail.icon width={32} height={32} />
							</div>
							<div className="d-flex flex-column align-self-center">
								<p className="text-base-700 text-primary">{detail.text}</p>
								{detail.textDetail && (
									<p className="text-sm-325 md-hide text-secondary">{detail.textDetail}</p>
								)}
							</div>
						</div>
						<div className="d-flex flex-row">
							<div className="d-flex flex-column align-items-end">
								{detail.button ? (
									<Button
										onClick={() => {
											if (detail?.buttonHandler) {
												detail.buttonHandler();
											} else if (detail?.link) {
												navigate(detail.link);
											}
										}}
										type={ButtonTypes.TERTIARY}
									>
										{detail.button}
									</Button>
								) : (
									<>
										{(detail.amount || detail.amount == '0') && (
											<p className={`text-base-700 text-primary`}>
												$ {getDecimalVal(Number(detail.amount))}
											</p>
										)}
										{detail.amountDetail && <p className="text-sm-325">{detail.amountDetail}</p>}
									</>
								)}
							</div>
							<div className="pl-16 d-flex align-items-center">
								<ChevronClose />
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
