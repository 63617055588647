import React from 'react';
import { getClassNames } from '../../../utils/Css';
import { Clear, Complete, Show, ShowDont } from '../../icons';
import { InputSizes, InputTypes } from './Input.constants';
import classes from './Input.module.scss';

type InputProps = {
	size?: InputSizes;
	type: InputTypes;
	isDisabled?: boolean;
	value: string;
	isCompleted?: boolean;
	isClearable?: boolean;
	isIncomplete?: boolean;
	isTypePassword?: boolean;
	isAmount?: boolean;
	maxLength?: number;
	placeholder?: string;
	autoComplete?: string;
	name?: string;
	onchange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onClear?: () => void;
	onChangeType?: () => void;
	onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	reference?: any;
};

export const Input = ({
	size = InputSizes.MD,
	type,
	value,
	isDisabled,
	isCompleted = false,
	isIncomplete = false,
	isClearable = false,
	isTypePassword = false,
	isAmount = false,
	onchange,
	onClear,
	onChangeType,
	onBlur,
	onFocus,
	placeholder = '',
	reference,
	...restProps
}: InputProps) => {
	// To get the css class for specific to size
	const getInputSize = (inputSize: string) => {
		let cssClass = '';
		switch (inputSize) {
			case InputSizes.MD:
				cssClass = classes.medium;
				break;
			case InputSizes.LG:
				cssClass = classes.large;
				break;
			case InputSizes.SM:
				cssClass = classes.small;
				break;
			default:
				break;
		}
		return cssClass;
	};

	const classNames = getClassNames(
		classes.input,
		isDisabled && classes.disabled,
		getInputSize(size),
		isAmount && classes['input-left-padding'],
		isCompleted && classes['is-completed'],
		isIncomplete && classes['is-in-completed']
	);

	// const isShowClearBtn =
	// 	(value?.length > 0 && !isIncomplete && isClearable && !isCompleted) ||
	// 	(isIncomplete && isTypePassword && value?.length > 0);
	const isShowClearBtn = value?.length > 0 || (isIncomplete && isTypePassword && value?.length > 0);
	return (
		<div className={classes['base-input']}>
			<input
				placeholder={placeholder}
				value={value}
				onChange={onchange}
				onBlur={onBlur}
				onFocus={onFocus}
				className={classNames}
				type={type}
				ref={reference || null}
				{...restProps}
			/>
			{isShowClearBtn && isTypePassword && isClearable && (
				<div
					onClick={onClear}
					className={`${classes['btn-icon-container']} ${
						isTypePassword && classes['left-aligned']
					} ${isCompleted && classes['on-complete-clear-left-aligned']} btn-clear`}
				>
					<Clear height={20} width={20} />
				</div>
			)}
			{isCompleted && (
				<div
					className={`${classes['btn-icon-container']} ${
						isTypePassword && !isCompleted && classes['left-aligned']
					} ${classes['no-cursor']} btn-completed}`}
				>
					<Complete color="#02880f" height={20} width={20} />
				</div>
			)}
			{/* {isIncomplete && !isTypePassword && (
				<div
					className={`${classes['btn-icon-container']} ${
						isTypePassword && !isCompleted && classes['left-aligned']
					} ${classes['no-cursor']}`}
				>
					<Incomplete color="#AA232C" height={20} width={20} />
				</div>
			)} */}
			{isTypePassword && (
				<div
					onClick={onChangeType}
					className={`${classes['btn-icon-container']} ${
						isCompleted && classes['on-complete-eye-left-aligned']
					} `}
				>
					{type !== InputTypes.PASSWORD ? (
						<Show height={20} width={20} />
					) : (
						<ShowDont height={20} width={20} />
					)}
				</div>
			)}
			{isAmount && <div className={`${classes['left-icon-container']}`}>$</div>}
		</div>
	);
};
