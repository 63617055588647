// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReimbursement = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 33 33"
		{...props}
	>
		<path
			fill={props.color || `#111827`}
			d="M13.464 26.285a1.352 1.352 0 0 1 .982-1.44l.125-.033v-1.778l-.18.017a2.7 2.7 0 0 0-2.387 2.443h-.5a.722.722 0 0 0-.01 1.444h.49a2.724 2.724 0 0 0 2.538 2.548c1.288.063 2.191-.76 2.675-2.444l.351-1.1c.194-.7.54-1.05 1.025-1.05.591 0 1.02.54 1.02 1.285a1.135 1.135 0 0 1-.9 1.268l-.138.022v1.75l.18-.014a2.571 2.571 0 0 0 2.357-2.255h.484a.722.722 0 0 0 .01-1.444h-.494a2.655 2.655 0 0 0-2.569-2.364c-1.223 0-2.057.755-2.55 2.313l-.3 1.1c-.245.847-.586 1.2-1.14 1.183-.646-.021-1.069-.588-1.069-1.451Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M24.03 12.062H8.988A2.962 2.962 0 0 0 6.03 15.02v11.057H2.49v5.119h28.02v-5.119h-3.52V15.021a2.962 2.962 0 0 0-2.959-2.959ZM6.023 29.546h-1.88v-1.825h1.88v1.825Zm1.7-14.526a1.054 1.054 0 0 1 1.053-1.053h2.967l-.012.06a5.052 5.052 0 0 1-3.949 3.949l-.059.012V15.02Zm17.585 11.057h-.016v3.471H7.723v-9.627l.043-.006a7 7 0 0 0 5.9-5.9l.007-.043h5.684l.006.043a7 7 0 0 0 5.9 5.9l.043.006.002 6.156Zm0-8.089-.06-.012a5.054 5.054 0 0 1-3.949-3.949l-.013-.06h2.97a1.054 1.054 0 0 1 1.052 1.053v2.968Zm3.552 9.735v1.817h-1.872v-1.819l1.872.002Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M16.338 20.784a1.236 1.236 0 1 0 0-2.472 1.236 1.236 0 0 0 0 2.472ZM20.944 6.415a392.57 392.57 0 0 0-3.825-3.787.915.915 0 0 0-.977-.211.827.827 0 0 0-.284.195l-.042.04a743.137 743.137 0 0 0-3.766 3.761.85.85 0 0 0 .582 1.488.848.848 0 0 0 .611-.288c.475-.467.945-.939 1.414-1.411l.325-.327c.086-.086.17-.174.272-.28l.4-.413v4.671a.863.863 0 0 0 .844.963.799.799 0 0 0 .577-.227 1.016 1.016 0 0 0 .27-.73V5.247l.325.3c.058.053.1.095.148.139.18.178.358.358.536.537.449.452.913.919 1.382 1.362.168.151.373.255.594.3a.582.582 0 0 0 .117.012.81.81 0 0 0 .706-.486.841.841 0 0 0-.209-.996Z"
		/>
	</svg>
);
export default SvgReimbursement;
