// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgChevronClose = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.188 3.004a1.179 1.179 0 0 1 .836.347l7.834 7.807a1.186 1.186 0 0 1 0 1.677l-7.834 7.836a1.185 1.185 0 0 1-1.676-1.675l6.995-6.998L8.35 5.027A1.185 1.185 0 0 1 9.187 3v.004Z"
			fill={props.color || `#111827`}
		/>
	</svg>
);
export default SvgChevronClose;
