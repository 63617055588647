// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReimburse = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill={props.color || `#111827`}
			d="M19 5.25H5A1.76 1.76 0 0 0 3.25 7v10A1.76 1.76 0 0 0 5 18.75h14A1.76 1.76 0 0 0 20.75 17V7A1.76 1.76 0 0 0 19 5.25ZM19.25 7v1.67h-.21a1.45 1.45 0 0 1-.36 0h-.13a1.8 1.8 0 0 1-.33-.1h-.12a1.29 1.29 0 0 1-.38-.28 1.578 1.578 0 0 1-.47-1.12c0-.139.02-.277.06-.41H19a.25.25 0 0 1 .25.24ZM5 6.75h1.69c.04.133.06.271.06.41a1.58 1.58 0 0 1-.47 1.12 1.49 1.49 0 0 1-.38.28h-.13a1.12 1.12 0 0 1-.31.1h-.14a1.45 1.45 0 0 1-.36 0h-.21V7A.25.25 0 0 1 5 6.75ZM4.75 17v-1.67h.21c.12-.015.24-.015.36 0h.13c.114.018.225.052.33.1h.12c.14.073.269.167.38.28a1.58 1.58 0 0 1 .47 1.12c0 .139-.02.277-.06.41H5a.25.25 0 0 1-.25-.24Zm3.47.25a2.73 2.73 0 0 0 0-.41 3 3 0 0 0-.91-2.18 2.627 2.627 0 0 0-.49-.39l-.17-.11a3.382 3.382 0 0 0-.36-.16l-.2-.08a3.326 3.326 0 0 0-.53-.11 1.559 1.559 0 0 0-.31 0h-.5v-3.59H5.6c.136-.023.27-.057.4-.1l.22-.07c.153-.052.3-.12.44-.2l.16-.09a3.42 3.42 0 0 0 .52-.42 3 3 0 0 0 .91-2.18 2.73 2.73 0 0 0 0-.41h7.56c-.01.136-.01.274 0 .41a3.001 3.001 0 0 0 .91 2.18c.157.16.331.3.52.42l.16.09c.14.08.287.148.44.2l.22.07c.134.04.271.07.41.09H19.31v3.56h-.5a1.622 1.622 0 0 0-.31 0c-.18.022-.357.059-.53.11l-.2.08a3.389 3.389 0 0 0-.39.18l-.17.11c-.178.11-.343.24-.49.39a3 3 0 0 0-.91 2.18c-.01.136-.01.273 0 .41l-7.59.02Zm10.78 0h-1.69a1.452 1.452 0 0 1-.06-.41 1.58 1.58 0 0 1 .47-1.12c.108-.116.237-.21.38-.28h.12c.107-.042.217-.076.33-.1h.13c.12-.015.24-.015.36 0h.21V17a.25.25 0 0 1-.25.25Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M12 8.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm0 5.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
		/>
	</svg>
);
export default SvgReimburse;
