/* eslint-disable max-lines */
import React, { useState, useRef } from 'react';
import classes from './LoginPage.module.scss';
// import usernameIcon from '../../assets/img/akar-icons_person.svg';
// import passwordIcon from '../../assets/img/carbon_password.svg';
import Warning from '../../components/icons/Warning';
import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserSession,
	CognitoUserAttribute
} from 'amazon-cognito-identity-js';

import UserPool from '../../utils/UserPool';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Input, InputTypes } from '../../components/common/Input';
import { FormControl } from '../../components/common/FormControl';
import { Button, ButtonTypes } from '../../components/common/Button';
import { Banner, BannerTypes } from '../../components/common/Banner';
import { StyledLink } from '../../components/common/Link';
import { localStorageUtil } from '../../utils/localStorageUtil';
import * as braze from '@braze/web-sdk';
import { signupApi } from '../../services/signupApi';
import {
	StyledLinkSizes,
	StyledLinkTypes
} from '../../components/common/Link/StyledLink.constants';

import { Loader } from '../../components/common/Loader';

// import { Header } from '../../components/Header';
// import logoSvg from '../../assets/img/vertical-splash.png';
import logoSvg from '../../assets/img/Incomm.svg';
import passwordSvg from '../../assets/img/lock.svg';
import phoneCheckSvg from '../../assets/img/PhoneCheck.svg';

import ReactInputVerificationCode from 'react-input-verification-code';
// import { Checkbox } from '../../components/common/Checkbox';
import { contactApi } from '../../services/profileService';
import { dateUtil } from '../../utils/dateUtil';
import { MfaStatus, DEFAULT_PHONE_NUMBER } from '../../constants/common';
import { ControlType } from '../../components/common/FormControl/FormControl.constants';

type FormValues = {
	username: string;
	password: string;
};

enum SCREENS {
	LOGIN = 'LOGIN',
	OTP = 'OTP',
	ENABLE_2FA = 'ENABLE_2FA',
	ADD_PHONE = 'ADD_PHONE'
}

export const LoginPage = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const emptyDivRef = useRef<any>(null);
	const user = useRef<null | CognitoUser>(null);

	// const [searchParams] = useSearchParams();
	const [isPasswordEyeOpen, setPasswordEye] = useState(false);
	const [isLoginFailed, setLoginFail] = useState(false);
	const [otpFailedMessage, setOTPFailedMessage] = useState<null | {
		message: string;
		__type: string;
	}>(null);
	const [blurredFields, setBlurredFields] = useState({ username: false, password: false });
	const [attributes, setAttribute] = useState<null | Record<string, string>>(null);
	const [screen, setScreen] = useState<string>(SCREENS.LOGIN);
	const [mfaChallengeParameters, setMfaChallengeParameters] = useState<null | {
		CODE_DELIVERY_DESTINATION: string;
		CODE_DELIVERY_DELIVERY_MEDIUM: string;
	}>(null);

	const [resendDisable, setResendDisable] = useState<boolean>(false);

	const {
		//register,
		handleSubmit,
		control,
		setValue,
		getValues,
		formState: { errors }
	} = useForm<FormValues>({
		mode: 'onBlur',
		defaultValues: {
			username: '',
			password: ''
		}
	});

	const {
		handleSubmit: handleOtpSubmit,
		control: otpControl,
		formState: { isValid: isOtpValidInput },
		setValue: setPasscodeValue
		// reset: otpReset
	} = useForm<{
		passcode: string;
		// rememberDevice: boolean;
	}>({
		defaultValues: {
			passcode: ''
			// rememberDevice: false
		},
		mode: 'onChange'
	});

	const {
		handleSubmit: handlePhoneSubmit,
		control: phoneControl,
		getValues: getPhoneValue,
		setValue: setPhoneValue,
		formState: { isValid: isPhoneValid }
		// formState: phoneFormState,
		// reset: phoneReset
	} = useForm<{
		phone_number: string;
	}>({
		defaultValues: {
			phone_number: ''
		},
		mode: 'onChange'
	});

	const getUser = () => {
		return user.current;
	};

	// useEffect(() => {
	// 	(window as any).gtag('event', 'page_view', {
	// 		page_title: 'Zenda Login ',
	// 		page_location: 'https://example.com/me', // Include the full URL
	// 		send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
	// 		screen_name: 'sign_In_screen'
	// 	});
	// 	(window as any).gtag('event', 'page_view', {
	// 		page_title: 'sign_In_screen',
	// 		app_name: 'Zenda-web',
	// 		// screen_name: 'sign_In_screen',
	// 		send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''
	// 	});
	// }, []);

	const moveToDashboard = () => {
		signupApi
			.fetchGaTags()
			.then(response => {
				braze.changeUser(response.data.data.braze_id);
				braze.logCustomEvent('login_completion');
			})
			.catch(error => {
				console.log(error);
			});

		const params: any = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParamss, prop: any): any => searchParamss.get(prop)
		});

		navigate(params?.redirectTo ? params?.redirectTo : '/apps/dashboard');
	};

	const updatePhone = async (phoneNumber: string) => {
		new Promise((resolve, reject) => {
			contactApi
				.updatePhoneNumber({
					phone_number: phoneNumber,
					sent_at: dateUtil.getEpochTime()
				})
				.then((updated: any) => {
					resolve(updated);
				})
				.catch((error: any) => {
					reject(error.response.data.error);
				});
		});
	};

	const setLocalStorage = (result: CognitoUserSession) => {
		localStorageUtil.setToken({
			accessToken: result.getAccessToken().getJwtToken(),
			refreshToken: result.getRefreshToken().getToken(),
			idToken: result.getIdToken().getJwtToken()
		});
		localStorage.removeItem('card-activation-dismissed');
		localStorage.removeItem('card-travel-dismissed');
	};

	// const setDeviceRemembered = (rememberDevice: boolean) => {
	// 	if (rememberDevice) {
	// 		getUser()?.setDeviceStatusRemembered({
	// 			onSuccess: function () {},
	// 			onFailure: function () {}
	// 		});
	// 	}
	// };

	const enableMFA = () => {
		setIsLoading(true);
		contactApi
			.setMFA(MfaStatus.ENABLE)
			.then(() => {
				moveToDashboard();
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
				moveToDashboard();
			});
	};

	const checkMFA = () => {
		contactApi
			.getMFA()
			.then(({ data }) => {
				if (data.data.prompt_mfa) {
					setIsLoading(false);
					setScreen(SCREENS.ENABLE_2FA);
				} else {
					setIsLoading(false);
					moveToDashboard();
				}
			})
			.catch(() => {
				setIsLoading(false);
				moveToDashboard();
			});
	};

	const handleLoginSubmit = (data: FormValues) => {
		//function call be called during resend otp
		setIsLoading(true);

		user.current = new CognitoUser({
			Username: data.username.trim(),
			Pool: UserPool
		});
		//  Create the AuthDetail object which needs to be passed in aws incognito
		const authDetails = new AuthenticationDetails({
			Username: data.username.trim(),
			Password: data.password.trim()
		});

		user.current.authenticateUser(authDetails, {
			onSuccess: (result: CognitoUserSession) => {
				setLocalStorage(result);
				// setIsLoading(false);
				// setScreen(SCREENS.ENABLE_2FA);
				checkMFA();
			},
			onFailure: () => {
				setIsLoading(false);
				setLoginFail(true);
				emptyDivRef?.current?.focus();
			},
			mfaRequired: (...mfaDetails) => {
				setIsLoading(false);
				setScreen(SCREENS.OTP);
				setMfaChallengeParameters(mfaDetails[1]);
			}
		});
	};

	const onSubmit = handleSubmit(handleLoginSubmit);

	const onOtpSubmit = handleOtpSubmit((data: { passcode: string }) => {
		setOTPFailedMessage(null);
		if (mfaChallengeParameters?.CODE_DELIVERY_DELIVERY_MEDIUM) {
			setIsLoading(true);
			getUser()?.sendMFACode(data.passcode, {
				onSuccess: result => {
					setIsLoading(false);
					setLocalStorage(result);
					// setDeviceRemembered(data.rememberDevice);
					moveToDashboard();
				},
				onFailure: otpError => {
					setIsLoading(false);
					setOTPFailedMessage({ message: otpError.message, __type: otpError.code || '' });
				}
			});
		} else {
			setIsLoading(true);
			getUser()?.verifyAttribute('phone_number', data.passcode, {
				async onSuccess() {
					try {
						setIsLoading(false);
						if (attributes?.phone_number_verified == 'false') {
							await updatePhone(getPhoneValue('phone_number').split('-').join(''));
						}
						enableMFA();

						// setDeviceRemembered(data.rememberDevice);
					} catch {}
				},
				onFailure(otpError: any) {
					setIsLoading(false);
					setOTPFailedMessage({ message: otpError.message, __type: otpError.code || '' });
				}
			});
		}
	});

	const handlePhoneSubmitEvent = (data: any) => {
		//can be called during resend event
		if (attributes?.phone_number_verified == 'true') {
			setIsLoading(true);
			getUser()?.getAttributeVerificationCode('phone_number', {
				onSuccess: function () {
					setIsLoading(false);
					setScreen(SCREENS.OTP);
				},
				onFailure: function () {
					setIsLoading(false);
				}
			});
		} else {
			if (attributes?.phone_number.slice(-10) == data.phone_number) {
				setIsLoading(true);
				getUser()?.getAttributeVerificationCode('phone_number', {
					onSuccess: function () {
						setIsLoading(false);
						setScreen(SCREENS.OTP);
					},
					onFailure: function () {
						setIsLoading(false);
					}
				});
			} else {
				setIsLoading(true);
				getUser()?.updateAttributes(
					[
						{
							Name: 'phone_number',
							Value: `+1${data.phone_number.split('-').join('')}`
						}
					],
					function () {
						getUser()?.getAttributeVerificationCode('phone_number', {
							onSuccess: function () {
								setIsLoading(false);
								setScreen(SCREENS.OTP);
							},
							onFailure: function () {
								setIsLoading(false);
							}
						});
					}
				);
			}
		}
	};

	const onPhoneSubmit = handlePhoneSubmit(handlePhoneSubmitEvent);

	const validatePhone = (): boolean => {
		return (getPhoneValue('phone_number').split('-').join('').length == 10 && true) || false;
	};

	// Take the use on forgot password page
	const redirectUser = () => {
		navigate('/forgot');
	};

	const resend = () => {
		setResendDisable(true);
		setTimeout(() => {
			setResendDisable(false);
		}, 20 * 1000);
		setPasscodeValue('passcode', '');
		if (mfaChallengeParameters?.CODE_DELIVERY_DELIVERY_MEDIUM) {
			handleLoginSubmit({
				username: getValues('username'),
				password: getValues('password')
			});
		} else {
			handlePhoneSubmitEvent({ phone_number: getPhoneValue('phone_number') });
		}
	};

	const skipMFA = (status: MfaStatus) => {
		setIsLoading(true);
		contactApi
			.setMFA(status)
			.then(() => {
				moveToDashboard();
				setIsLoading(false);
			})
			.catch(() => {
				moveToDashboard();
				setIsLoading(false);
			});
	};

	const getHeader = () => {
		if (isLoading) {
			return <img src={logoSvg} width="250px"></img>;
		} else if (screen == SCREENS.LOGIN) {
			return (
				<img
					onClick={() => {
						navigate('/');
					}}
					src={logoSvg}
					width="250px"
				></img>
			);
		} else if (screen == SCREENS.OTP) {
			return (
				<div className="d-flex flex-column align-items-center">
					<img
						onClick={() => {
							navigate('/');
						}}
						src={passwordSvg}
						width="200px"
					></img>
					<p className={`text-h2-325 py-16 pt-64 ${classes['text-yellow']}`}>
						2-Step Authentication
					</p>
				</div>
			);
		} else if (screen == SCREENS.ENABLE_2FA) {
			return (
				<div className={`d-flex flex-column align-items-center ${classes['form-width']}`}>
					<img
						onClick={() => {
							navigate('/');
						}}
						src={passwordSvg}
						width="200px"
					></img>
					<p className={`text-h2-325 pt-48 text-center ${classes['text-yellow']}`}>
						Would you like to enable 2-Step Authentication?
					</p>
				</div>
			);
		} else if (screen == SCREENS.ADD_PHONE) {
			return (
				<div className="d-flex flex-column align-items-center">
					<img
						onClick={() => {
							navigate('/');
						}}
						src={phoneCheckSvg}
						width="200px"
					></img>
					<p className={`text-h2-325 pt-48 ${classes['text-yellow']}`}>Authenticate via Phone</p>
				</div>
			);
		}
	};
	const getContent = () => {
		if (isLoading) {
			return <Loader />;
		}
		if (screen == SCREENS.LOGIN) {
			return (
				<div className={classes['login-card']}>
					{isLoginFailed && (
						<span className={classes.banner}>
							<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
								<span className={classes['message-text']}>
									The email address and/or password does not match our records. Please try again or
									tap&nbsp;
									<StyledLink
										size={StyledLinkSizes.SM}
										href="/forgot"
										isOpenInNewTab={false}
										hideUnderLine={false}
									>
										forgot password.
									</StyledLink>
									{/* .&nbsp;If you have lost access to your email, please{' '}
							<StyledLink size={StyledLinkSizes.SM} href="mailto:support@incommbenefits.com">
								contact us
							</StyledLink>{' '}
							for assistance. */}
								</span>
							</Banner>
						</span>
					)}
					<br></br>
					{/* <div>
						<p className={classes['login-title']}>Log In</p>
					</div> */}
					<form onSubmit={onSubmit} className={classes['form-width']}>
						<div>
							<Controller
								control={control}
								name="username"
								rules={{
									required: 'Email is required.',
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Email is not valid.'
									}
								}}
								render={({
									field: { onChange, onBlur, value },
									fieldState: { error, isDirty: isFieldDirty }
								}) => (
									<>
										<FormControl
											label={() => 'Email Address'}
											controlType={ControlType.LIGHT}
											error={() => error?.message || ''}
										>
											<Input
												reference={emptyDivRef}
												onchange={onChange}
												onBlur={() => {
													onBlur();
													setBlurredFields({ ...blurredFields, username: true });
												}}
												onClear={() => {
													setValue('username', '');
												}}
												isCompleted={
													!errors?.username?.message && isFieldDirty && blurredFields.username
												}
												isIncomplete={error ? true : false}
												isClearable={true}
												type={InputTypes.EMAIL}
												value={value}
												name="zenda-email"
												autoComplete="chrome-off"
											/>
										</FormControl>
									</>
								)}
							/>
						</div>

						<div>
							<Controller
								control={control}
								name="password"
								rules={{
									required: 'Password is required.',
									minLength: { value: 8, message: 'That password is too short.' }
								}}
								render={({
									field: { onChange, onBlur, value },
									fieldState: { error, isDirty: isFieldDirty }
								}) => (
									<>
										<FormControl
											label={() => 'Password'}
											controlType={ControlType.LIGHT}
											error={() => error?.message || ''}
										>
											<Input
												isClearable={true}
												onClear={() => {
													setValue('password', '');
												}}
												isCompleted={
													!errors?.password?.message && isFieldDirty && blurredFields.password
												}
												isIncomplete={error ? true : false}
												isTypePassword={true}
												onchange={onChange}
												onBlur={() => {
													onBlur();
													setBlurredFields({ ...blurredFields, password: true });
												}}
												onChangeType={() => {
													setPasswordEye(!isPasswordEyeOpen);
												}}
												type={isPasswordEyeOpen ? InputTypes.TEXT : InputTypes.PASSWORD}
												value={value}
											/>
										</FormControl>
									</>
								)}
							/>
						</div>

						<div className={'d-flex flex-column pt-16'}>
							<Button
								onClick={() => {
									emptyDivRef?.current?.focus();
								}}
								type={ButtonTypes.TERTIARY}
							>
								Log In
							</Button>
						</div>
						<div className="d-flex w-100 justify-content-between pt-32">
							<p>
								<StyledLink
									size={StyledLinkSizes.LG}
									onClick={redirectUser}
									type={StyledLinkTypes.TERTIARY}
								>
									Forgot password?
								</StyledLink>
							</p>

							<p>
								<StyledLink
									size={StyledLinkSizes.LG}
									type={StyledLinkTypes.TERTIARY}
									onClick={() => {
										navigate('/signup');
									}}
								>
									Sign Up
								</StyledLink>
							</p>
						</div>
					</form>
					{/* <span className={classes.forgotLink}>Forgot password?</span> */}
				</div>
			);
		} else if (screen == SCREENS.OTP) {
			return (
				<div className={classes['login-card']}>
					{otpFailedMessage && (
						<span className={classes.banner}>
							<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
								<span className={classes['message-text']}>
									{otpFailedMessage?.__type == 'CodeMismatchException' && (
										<div>
											<p className="text-base-700">The Verification Code is incorrect</p>
											<p>Please click ‘Resend’ to receive another code.</p>
										</div>
									)}
									{otpFailedMessage?.__type == 'NotAuthorizedException' && (
										<div>
											<p className="text-base-700">The Verification Code has Expired</p>
											<p>Please click ‘Resend’ to receive another code.</p>
										</div>
									)}
								</span>
							</Banner>
						</span>
					)}
					<form
						onSubmit={onOtpSubmit}
						className={`${classes['form-width']} ${classes['margin-top-none']}`}
					>
						<p className={`text-base-325 py-16 ${classes['text-white']}`}>
							A text message with a 6-digit verification code was just sent to (•••) •••-••
							{(
								getPhoneValue('phone_number') || mfaChallengeParameters?.CODE_DELIVERY_DESTINATION
							)?.slice(-2)}
						</p>
						<div>
							<Controller
								control={otpControl}
								name="passcode"
								rules={{
									required: 'Passcode is required.',
									minLength: { value: 6, message: 'Please enter 6 digit Passcode.' }
								}}
								render={({ field: { onChange, value }, fieldState: {} }) => (
									<>
										<FormControl
											controlType={ControlType.LIGHT}
											label={() => 'Enter the code'}
											caption={() => ''}
										>
											<ReactInputVerificationCode
												value={value}
												length={6}
												placeholder={''}
												onChange={onChange}
												autoFocus={true}
											/>
										</FormControl>
									</>
								)}
							/>
						</div>
						{/* <div className={'d-flex justify-content-center py-16'}>
							<Controller
								control={otpControl}
								name="rememberDevice"
								render={({ field: { onChange, value }, fieldState: {} }) => (
									<div className="d-flex align-items-start align-items-center">
										<Checkbox checked={value} onChange={onChange}>
											<p className="text-base-350">Remember this device for 90 days.</p>
										</Checkbox>
									</div>
								)}
							/>
						</div> */}

						<div className={'d-flex flex-column gap-20 py-16'}>
							<Button isDisabled={!isOtpValidInput} type={ButtonTypes.TERTIARY}>
								Verify
							</Button>
							<Button
								onClick={e => {
									e.preventDefault();
									setPasscodeValue('passcode', '');
									setOTPFailedMessage(null);
									if (mfaChallengeParameters?.CODE_DELIVERY_DELIVERY_MEDIUM) {
										setMfaChallengeParameters(null);
										setScreen(SCREENS.LOGIN);
									} else {
										setScreen(SCREENS.ADD_PHONE);
									}
								}}
								type={ButtonTypes.PRIMARY}
							>
								Back
							</Button>
						</div>
						<p className="d-flex justify-content-center text-base-325 p-8">
							<p className={`${classes['text-white']} pr-8`}>Didn’t get the code?</p>
							<span className="px-4">
								<StyledLink
									type={StyledLinkTypes.TERTIARY}
									isDisabled={resendDisable}
									onClick={resend}
								>
									<span className="text-base-350">Resend</span>
								</StyledLink>{' '}
							</span>
						</p>
					</form>
				</div>
			);
		} else if (screen == SCREENS.ENABLE_2FA) {
			return (
				<div className={classes['login-card']}>
					<div
						className={`d-flex justify-content-center ${classes['form-width']} ${classes['margin-top-none']}`}
					>
						<p className={`text-base-325 pb-16 text-center ${classes['text-white']}`}>
							Two-step authentication is an extra layer of security to help keep your account
							protected in the event your login ID and/or Password are compromised.
						</p>
						{/* <p className="text-base-325 pb-32 text-center">
							After login, we will send a code to the method you choose:
						</p> */}
						<div className="pt-16 pb-4 d-flex justify-content-center">
							<Button
								type={ButtonTypes.TERTIARY}
								onClick={() => {
									setScreen(SCREENS.ADD_PHONE);
									setIsLoading(true);
									getUser()?.getUserAttributes((err?: any, result?: CognitoUserAttribute[]) => {
										setIsLoading(false);
										if (err) {
											return;
										}
										const userAttributes: Record<string, string> = {};
										result?.forEach(attribute => {
											userAttributes[attribute.getName()] = attribute.getValue();
										});
										if (
											userAttributes.phone_number &&
											userAttributes.phone_number != DEFAULT_PHONE_NUMBER
										) {
											setPhoneValue(
												'phone_number',
												userAttributes?.phone_number
													.slice(-10)
													.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3') || ''
											);
										}

										setAttribute(userAttributes);
									});
								}}
							>
								Text to My Phone
							</Button>
						</div>
						<div className="pt-16 text-center d-flex justify-content-center">
							<StyledLink
								type={StyledLinkTypes.TERTIARY}
								onClick={() => {
									skipMFA(MfaStatus.SKIP_FOR_NOW);
								}}
							>
								Skip for now
							</StyledLink>
						</div>
						<div className="pt-16 text-center d-flex justify-content-center">
							<StyledLink
								type={StyledLinkTypes.TERTIARY}
								onClick={() => {
									skipMFA(MfaStatus.SKIP_FOREVER);
								}}
							>
								Skip and don't ask me again
							</StyledLink>
						</div>

						<p className={`text-base-325 py-16 text-center ${classes['text-white']}`}>
							You can enable 2-step authentication at any time from your user profile.
						</p>
					</div>
				</div>
			);
		} else if (screen == SCREENS.ADD_PHONE) {
			return (
				<div className={classes['login-card']}>
					<form
						onSubmit={onPhoneSubmit}
						className={`${classes['form-width']} ${classes['margin-top-none']}`}
					>
						{attributes?.phone_number_verified != 'true' && (
							<p className={`text-base-325 pb-16 ${classes['text-white']}`}>
								InComm Benefits will text a verification code to this number to authenticate your
								account.
							</p>
						)}
						{attributes?.phone_number_verified == 'true' && (
							<p className={`text-base-325 ${classes['text-white']}`}>
								We found a number attached to your account. This is where the code will be sent.
							</p>
						)}
						{attributes?.phone_number_verified == 'true' && (
							<p className={`text-base-325 pt-8 pb-16 ${classes['text-white']}`}>
								If you want to use a different phone number, you will need to change it from your
								user profile, then set up 2-step authentication.
							</p>
						)}
						<div>
							<Controller
								control={phoneControl}
								name="phone_number"
								rules={{
									validate: validatePhone
								}}
								render={({ field: { onChange, value } }) => (
									<>
										<FormControl
											controlType={ControlType.LIGHT}
											label={() => 'Phone Number on File'}
											caption={() =>
												`By clicking “Send Verification Code,” you agree to receive a one-time SMS from InComm Benefits. Message and data rates may apply.`
											}
										>
											<Input
												onchange={evt => {
													const phoneNumber = evt.target.value.split('-').join('');

													if (!/^[0-9]*\.?[0-9]*$/.test(phoneNumber)) {
														evt.preventDefault();
													} else {
														evt.target.value =
															phoneNumber.length <= 6
																? phoneNumber.replace(/(\d{3})(\d{1})/, '$1-$2')
																: phoneNumber.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3');
														onChange(evt);
													}
												}}
												onClear={() => {
													setPhoneValue('phone_number', '');
												}}
												isClearable={true}
												type={InputTypes.TEXT}
												value={value}
												placeholder={'Phone Number'}
												maxLength={12}
												isCompleted={isPhoneValid}
												isDisabled={attributes?.phone_number_verified == 'true'}
											/>
										</FormControl>
									</>
								)}
							/>
						</div>

						<div className={'d-flex flex-column gap-16 py-16'}>
							<Button isDisabled={!isPhoneValid} type={ButtonTypes.TERTIARY}>
								Send Verification Code
							</Button>
							<Button
								onClick={e => {
									e.preventDefault();
									setScreen(SCREENS.ENABLE_2FA);
								}}
								type={ButtonTypes.PRIMARY}
							>
								Back
							</Button>
						</div>
					</form>
				</div>
			);
		}
	};
	return (
		<React.Fragment>
			<div
				className={`${classes.header} ${
					screen != SCREENS.LOGIN && classes['header-bottom-padding']
				}`}
			>
				{getHeader()}
			</div>
			<div className={classes.content}>{getContent()}</div>
		</React.Fragment>
	);
};
