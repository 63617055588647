import fileTypeChecker from 'file-type-checker';

export const fileUtils = {
	validateFileType: async (file: File, fileTypes = ['jpeg', 'png', 'pdf']) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				if (
					fileTypeChecker.validateFileType(reader.result as ArrayBuffer, fileTypes, {
						excludeSimilarTypes: true
					})
				) {
					resolve(file);
				} else {
					reject('Invalid format');
				}
			};
			reader.readAsArrayBuffer(file);
		});
	},

	validFilesSize: (files: any, maxMb = 10) => {
		for (let i = 0; i < files.length; i = i + 1) {
			if (files[i].size / 1000000 > maxMb) {
				return false;
			}
		}
		return true;
	}
};
