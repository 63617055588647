// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInterestPayment = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 33 33"
		{...props}
	>
		<path
			fill={props.color || `#111827`}
			d="m19.615 10.289-8.326 8.326 1.2 1.2 8.327-8.326-1.2-1.2ZM16.361 17.887a2.522 2.522 0 1 0 2.543-2.528 2.542 2.542 0 0 0-2.543 2.528Zm3.6.021a1.072 1.072 0 1 1-2.143-.057 1.072 1.072 0 0 1 2.143.057ZM13.24 9.714a2.513 2.513 0 1 0-.007 5.027 2.513 2.513 0 0 0 .008-5.027Zm0 3.594a1.072 1.072 0 0 1-.77-1.821c.196-.204.465-.324.749-.334a1.108 1.108 0 0 1 1.1 1.088 1.086 1.086 0 0 1-1.074 1.067h-.004ZM6.011 17.478h1.753v-1.565H9.33v-1.755H7.76v-1.565H6.007v1.565H4.443v1.753h1.564l.004 1.567Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M31.792 7.713a3.722 3.722 0 0 0-3.717-3.718H9.732a3.722 3.722 0 0 0-3.718 3.718v3.63h1.75v-3.63a1.97 1.97 0 0 1 1.968-1.968h15.2a3.683 3.683 0 0 0-.576 1.968l.005 17.772a1.848 1.848 0 0 1-.523 1.353 1.924 1.924 0 0 1-1.4.586 1.97 1.97 0 0 1-1.968-1.968v-1.65H7.761v-5.075h-1.75v5.075H1.533v1.65a3.722 3.722 0 0 0 3.717 3.718h17.195a3.656 3.656 0 0 0 2.652-1.119 3.562 3.562 0 0 0 1.016-2.6V10.631h5.68V7.713ZM5.253 27.424a1.969 1.969 0 0 1-1.964-1.868h15.442a3.7 3.7 0 0 0 .563 1.868H5.254Zm24.79-18.542H26.11V7.713a1.966 1.966 0 0 1 3.931 0v1.169Z"
		/>
	</svg>
);
export default SvgInterestPayment;
