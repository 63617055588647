// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgFaq = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 32 32"
		fill="none"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32Zm0 29.333a13.334 13.334 0 1 1 0-26.668 13.334 13.334 0 0 1 0 26.668ZM11.911 8.356a5.778 5.778 0 0 0-1.689 4.088 1.333 1.333 0 0 0 2.667 0 3.092 3.092 0 0 1 .907-2.204 3.2 3.2 0 0 1 4.408 0A3.11 3.11 0 0 1 16 15.573a1.35 1.35 0 0 0-1.333 1.334v1.777a1.333 1.333 0 1 0 2.666 0v-.604c1.045-.24 2-.77 2.756-1.529a5.796 5.796 0 0 0 0-8.178 5.938 5.938 0 0 0-8.178-.017Zm6.311 15.2a2.222 2.222 0 1 1-4.444 0 2.222 2.222 0 0 1 4.444 0Z"
			fill={props.color || `#111827`}
		/>
	</svg>
);
export default SvgFaq;
