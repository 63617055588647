// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#warning_svg__a)">
			<path
				d="M22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12Z"
				stroke={props.color || `#25282B`}
			/>
			<path
				d="M12 22.241c5.656 0 10.241-4.585 10.241-10.241S17.656 1.759 12 1.759 1.759 6.344 1.759 12 6.344 22.241 12 22.241Z"
				stroke={props.color || `#25282B`}
				strokeWidth={2}
			/>
			<path
				d="M11.252 19.343a1.317 1.317 0 0 1 .935-2.251 1.214 1.214 0 0 1 .901.38 1.357 1.357 0 0 1 0 1.868 1.212 1.212 0 0 1-.9.379 1.274 1.274 0 0 1-.936-.376Zm1.891-14.55-.236 10.62h-1.614l-.233-10.62h2.083Z"
				fill={props.color || `#25282B`}
			/>
		</g>
		<defs>
			<clipPath id="warning_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgWarning;
