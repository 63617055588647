// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgProfile = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 26 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M21.519 4.481A12.047 12.047 0 0 0 4.48 21.52 12.047 12.047 0 0 0 21.52 4.48ZM9.17 11.491a3.83 3.83 0 1 1 7.662.01 3.83 3.83 0 0 1-7.663-.013l.001.004Zm9.838 10.288a10.612 10.612 0 0 1-13.24-.98 10.635 10.635 0 1 1 14.46 0 10.79 10.79 0 0 1-1.221.972v.008Zm.802-2.113a7.53 7.53 0 0 0-3.954-3.783 5.242 5.242 0 1 0-5.713 0 7.528 7.528 0 0 0-3.95 3.772l1.133.919a6.106 6.106 0 0 1 11.342 0l1.142-.908Z"
			fill={props.color || `#111827`}
			stroke="#EFF3FA"
			strokeWidth={0.5}
		/>
	</svg>
);
export default SvgProfile;
