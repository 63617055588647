// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgIncomplete = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#Incomplete_svg__a)">
			<path
				d="m20.487 18.601-6.601-6.6 6.6-6.601a1.332 1.332 0 1 0-1.884-1.885l-6.6 6.601-6.601-6.6A1.333 1.333 0 1 0 3.516 5.4l6.601 6.6-6.6 6.601A1.332 1.332 0 0 0 5.4 20.486l6.6-6.601 6.601 6.6a1.333 1.333 0 0 0 1.885-1.884Z"
				fill={props.color || `#111827`}
			/>
		</g>
		<defs>
			<clipPath id="Incomplete_svg__a">
				<path fill="#fff" d="M0 0h24.001v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgIncomplete;
