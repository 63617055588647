// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCar = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 19 18"
		{...props}
	>
		<path
			fill={props.color || `#111827`}
			d="m15.335 7.08-1.38-3.75a1.312 1.312 0 0 0-1.23-.893h-6.45a1.312 1.312 0 0 0-1.23.863l-1.38 3.75a1.32 1.32 0 0 0-.75 1.17v3.375a1.297 1.297 0 0 0 .585 1.11v1.545a.75.75 0 0 0 .75.75H5a.75.75 0 0 0 .75-.75v-1.313h7.5v1.313A.75.75 0 0 0 14 15h.75a.75.75 0 0 0 .75-.75v-1.545a1.298 1.298 0 0 0 .57-1.08V8.25a1.32 1.32 0 0 0-.735-1.17Zm-.397 4.545a.188.188 0 0 1-.188.188H4.25a.188.188 0 0 1-.187-.188V8.25a.187.187 0 0 1 .187-.188h10.5a.188.188 0 0 1 .188.188v3.375ZM6.103 3.683a.188.188 0 0 1 .172-.12h6.45a.187.187 0 0 1 .173.12l1.05 2.88H5.053l1.05-2.88Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M6.5 11.063a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM12.5 11.063a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
		/>
	</svg>
);
export default SvgCar;
