import React, { useEffect } from 'react';
import classes from './AccountLink.module.scss';
import { Button, ButtonTypes } from '../../common/Button';
import { Bank, ChevronClose } from '../../../components/icons';

import { Loader } from '../../../components/common/Loader';
import setupSvg from '../../../assets/img/SetUp.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountSummary } from '../../../store/accountSummarySlice';
import { changeBackground } from '../../../store/backgroundSlice';
// import { ContactUs } from '../../ContactUs';
// import { StyledLinkTypes } from '../../common/Link/StyledLink.constants';
import { useLocation, useNavigate } from 'react-router-dom';

type StepProps = {
	nextStep: () => void;
};

export const AccountLink = ({ nextStep }: StepProps) => {
	const navigate = useNavigate();

	const dispatch = useDispatch<any>();

	const { state: locationState } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(changeBackground(true));
		return () => {
			dispatch(changeBackground(false));
		};
	}, [dispatch]);

	const summaryData = useSelector((state: any) => state.summaryData);
	// const backgroundData = useSelector((state: any) => state.backgroundData);

	useEffect(() => {
		if ((!summaryData.loading && summaryData.data.external_account_linked) || locationState?.id) {
			nextStep();
		}
		if (!summaryData.data.summary.length) {
			dispatch(getAccountSummary());
		}
	}, [nextStep, summaryData, dispatch, locationState]);

	if (summaryData.loading) {
		return <Loader />;
	}

	return (
		<>
			<div className={`d-flex flex-column justify-content-center mt-32 ${classes.container}`}>
				<img className={classes['image-position']} width="175" height="175" src={setupSvg}></img>
				<p className={`d-flex text-h4-325 pb-12 text-yellow`}>Ope. Want to set this up first?</p>
				<div className={`d-flex flex-column text-white`}>
					<p className="text-base-350">
						For a quick an easy reimbursement, we recommend that you first link your external
						bank account.
					</p>
					<p className="text-base-325 mt-4">
						All expense reimbursements will be deposited directly into your bank account.
					</p>
				</div>
				<div
					className={`${classes['account-link']} my-32 d-flex justify-content-between align-items-center p-16`}
					onClick={() => {
						navigate('/apps/linkaccount');
					}}
				>
					<div className="d-flex align-items-center">
						<Bank width={32} height={32} />
						<p className="text-base-700 px-12">External Account</p>
					</div>
					<div className="d-flex align-items-center">
						<span className="px-12">
							<Button type={ButtonTypes.TERTIARY}>Connect</Button>
						</span>
						<ChevronClose />
					</div>
				</div>
				<div>
					<p className={`text-sm-325 mb-32 text-white`}>
						If you prefer to skip this step, we can still send you a check via mail. Check
						processing can take 10 to 15 business days.
					</p>
				</div>
				<Button
					type={ButtonTypes.TERTIARY}
					onClick={() => {
						nextStep();
					}}
				>
					Proceed Anyway
				</Button>
			</div>
		</>
	);
};
