// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgFsa = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 32 32"
		fill="none"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m27.774 13.65-1.719-1.3a.844.844 0 1 0-1.018 1.349l3.175 2.405a.84.84 0 0 0 .511.174l-.004-.005a.857.857 0 0 0 .42-.109.856.856 0 0 0 .34-.337l2.351-3.105a.844.844 0 1 0-1.349-1.019l-1.075 1.421c-1.058-6.678-6.651-11.79-13.374-11.79-5.96 0-11.156 3.969-12.936 9.875a.844.844 0 0 0 .566 1.052.844.844 0 0 0 1.053-.566c1.56-5.187 6.109-8.67 11.317-8.67 5.966 0 10.918 4.625 11.742 10.626ZM16.51 14.718l-1.175-.376c-.749-.207-1.125-.575-1.125-1.095 0-.63.58-1.09 1.374-1.09a1.23 1.23 0 0 1 .871.21c.254.178.427.449.486.75l.026.147h1.868l-.017-.19a2.746 2.746 0 0 0-2.41-2.52v-.515a.755.755 0 0 0-.22-.545.766.766 0 0 0-.545-.23.755.755 0 0 0-.545.22.769.769 0 0 0-.228.546v.528a2.841 2.841 0 0 0-2.528 2.748c0 1.307.807 2.199 2.473 2.727l1.175.321c.905.263 1.281.626 1.264 1.218-.017.693-.621 1.141-1.547 1.141a1.448 1.448 0 0 1-1.543-1.052l-.034-.136h-1.898l.017.195a2.885 2.885 0 0 0 2.608 2.553v.533a.756.756 0 0 0 .22.545.77.77 0 0 0 .841.174.765.765 0 0 0 .482-.71v-.52a2.89 2.89 0 0 0 1.873-.846 2.895 2.895 0 0 0 .85-1.869c.063-1.378-.816-2.342-2.617-2.857l.004-.005ZM3.197 14.57a.877.877 0 0 1 .672.204l3.112 2.391a.843.843 0 1 1-1.027 1.34l-1.658-1.274c.842 5.98 5.785 10.583 11.736 10.583 5.285 0 9.977-3.703 11.414-9.008a.846.846 0 0 1 1.632.44c-1.636 6.04-7 10.26-13.046 10.26-6.741 0-12.347-5.142-13.382-11.848l-1.127 1.465a.846.846 0 0 1-.672.33l-.004-.005a.842.842 0 0 1-.672-1.357l2.46-3.2a.826.826 0 0 1 .562-.321Z"
			fill={props.color || `#111827`}
		/>
	</svg>
);
export default SvgFsa;
