/* eslint-disable max-lines */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import classes from './StepThree.module.scss';
import { Button, ButtonTypes } from '../../common/Button';
import { FormControl } from '../../common/FormControl';
import { Input, InputTypes } from '../../common/Input';
import { UserData } from '../../../types/signup.types';
import lockSvg from '../../../assets/img/lock.svg';
import { StyledLink } from '../../common/Link';
import { StyledLinkTypes } from '../../common/Link/StyledLink.constants';
import { ControlType } from '../../common/FormControl/FormControl.constants';
import { PasswordHint } from '../../common/PasswordHint';

type StepThreeFormValues = {
	password: string;
};

type StepProps = {
	onNextStep: () => void;
	onPreviousStep: () => void;
	userData: UserData;
	setUserData: (data: UserData) => void;
};

export const StepThree = ({ onNextStep, userData, setUserData }: StepProps) => {
	const [isPasswordEyeOpen, setPasswordEye] = useState(false);

	const {
		handleSubmit,
		control,
		setValue,

		formState: { dirtyFields, isValid }
		//watch
	} = useForm<StepThreeFormValues>({
		mode: 'onChange',
		defaultValues: {
			password: ''
		}
	});

	//watch(['password']);

	// useEffect(() => {
	// 	(window as any).gtag('event', 'page_view', {
	// 		app_name: 'Zenda-web',
	// 		page_title: 'Onboarding_create_login',
	// 		// screen_name: 'Onboarding_create_login',
	// 		send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''
	// 	});
	// }, []);

	const onSubmit = handleSubmit(async (data: StepThreeFormValues) => {
		setUserData({ ...userData, password: data.password });
		onNextStep();
	});

	return (
		<div className="pt-32">
			<div className={classes.header}>
				<img src={lockSvg} width="200px" alt="create-password"></img>
				<p className={`text-h2-325 ${classes.title}`}>
					Hi {userData?.first_name}! Let’s create your login.
				</p>
			</div>
			<div className={classes.card}>
				<form onSubmit={onSubmit} className={classes.form} autoComplete="off">
					<p className={`pb-48 ${classes.info}`}>
						Enter a password an accept the terms. You can change your email after the initial setup.
					</p>
					<div>
						<FormControl controlType={ControlType.LIGHT} label={() => 'Email'}>
							<Input
								type={InputTypes.TEXT}
								value={userData.email?.toLowerCase().trim() || ''}
								isDisabled={true}
							/>
						</FormControl>
					</div>
					<div>
						<Controller
							control={control}
							name="password"
							rules={{
								required: 'password',
								minLength: { value: 8, message: 'password required' },
								pattern: {
									value:
										/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]).{8,}$/,
									message: ''
								}
							}}
							render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
								<>
									<FormControl label={() => 'Enter password'} controlType={ControlType.LIGHT}>
										<Input
											isClearable={true}
											onClear={() => {
												setValue('password', '', { shouldValidate: true });
											}}
											isIncomplete={error ? true : false}
											isTypePassword={true}
											onBlur={onBlur}
											onchange={onChange}
											onChangeType={() => {
												setPasswordEye(!isPasswordEyeOpen);
											}}
											type={isPasswordEyeOpen ? InputTypes.TEXT : InputTypes.PASSWORD}
											value={value || ''}
										/>
									</FormControl>
								</>
							)}
						/>
					</div>
					<div>
						{dirtyFields.password && <PasswordHint control={control} fieldName={'password'} />}
					</div>
					<br />

					<div className={`d-flex flex-column gap-20 mt-32`}>
						<Button type={ButtonTypes.TERTIARY} isDisabled={!isValid}>
							Next
						</Button>
					</div>
				</form>
				<p className="text-base-325 p-16 mt-16">
					<span className={classes['lost-text']}>Lost access to email?</span>
					<StyledLink href="mailto:support@incommbenefits.com" type={StyledLinkTypes.TERTIARY}>
						Contact Us
					</StyledLink>
				</p>
			</div>
		</div>
	);
};
