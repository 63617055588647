import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const backgroundSlice = createSlice({
	name: 'backgroundSlice',
	initialState: {
		backgroundDark: false
	},
	reducers: {
		changeBackground: (state, action: PayloadAction<boolean>) => {
			state.backgroundDark = action.payload;
		}
	}
});

export default backgroundSlice.reducer;
export const { changeBackground } = backgroundSlice.actions;
