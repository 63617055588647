// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgContribute = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 32 32"
		{...props}
	>
		<g clipPath="url(#contribute_svg__a)">
			<path
				fill="#111827"
				fillRule="evenodd"
				d="M31.022 13.69a2.732 2.732 0 0 0-3.854.28l.104.085-.125-.054-3.79 3.986a2.735 2.735 0 0 0-1.863-.917 9.337 9.337 0 1 0-11.362-.688A8.302 8.302 0 0 0 3.13 20.67a2.037 2.037 0 0 0-1.788.613L.23 22.457a.814.814 0 0 0 0 1.132l7.402 7.637a.77.77 0 0 0 .546.24h.015a.762.762 0 0 0 .53-.209l1.2-1.201a2.196 2.196 0 0 0 .52-2.147l.631-.624a1.802 1.802 0 0 1 1.282-.531h8.77a5.986 5.986 0 0 0 4.22-1.752c.023-.018.023-.018 1.128-1.37l.003-.004 4.867-5.956a2.865 2.865 0 0 0-.321-3.981ZM8.81 28.919l-.61.609-6.3-6.494.568-.605a.53.53 0 0 1 .67-.065c.034.02.137.126 1.12 1.134l.094.096.002.002c.809.829 2.164 2.218 4.455 4.559a.553.553 0 0 1 0 .764Zm7.44-11.935a7.625 7.625 0 1 1 7.617-7.624v.124a7.622 7.622 0 0 1-7.617 7.5Zm0 1.704c.118 0 .234-.008.35-.016l.151-.01h4.545a1.221 1.221 0 0 1 .002 2.44h-5.38a.806.806 0 0 0 0 1.61H21.3a2.806 2.806 0 0 0 2.748-3.137l2.904-3.054c1.265-1.33 1.361-1.432 1.378-1.455a1.182 1.182 0 0 1 1.663-.153l.01.007a1.238 1.238 0 0 1 .139 1.726l-5.906 7.22a4.373 4.373 0 0 1-3.1 1.278h-8.77a3.355 3.355 0 0 0-2.38.984l-.468.462-5.012-5.123a6.679 6.679 0 0 1 5.853-3.49h.001c.09 0 .182 0 .276.006h.02a6.869 6.869 0 0 1 2.835.608c.1.045.208.068.318.068h1.942l.151.01c.116.01.231.019.348.019Zm-3.14 2.745a.81.81 0 1 0 1.119 1.168.81.81 0 0 0-1.12-1.168Zm2.783-13.155 1.181.374c1.805.519 2.683 1.483 2.62 2.868a2.91 2.91 0 0 1-2.736 2.717v.514a.772.772 0 0 1-1.545 0v-.536a2.88 2.88 0 0 1-2.611-2.552l-.02-.193h1.895l.033.134a1.44 1.44 0 0 0 1.54 1.05c.922 0 1.53-.45 1.546-1.144.018-.596-.359-.958-1.264-1.22l-1.173-.326c-1.666-.526-2.472-1.417-2.472-2.726a2.838 2.838 0 0 1 2.527-2.746v-.52a.772.772 0 0 1 1.545 0v.52a2.749 2.749 0 0 1 2.411 2.52l.019.192h-1.87l-.024-.15a1.226 1.226 0 0 0-1.35-.963c-.798 0-1.376.459-1.376 1.09 0 .52.378.89 1.124 1.097Z"
				clipRule="evenodd"
			/>
		</g>
		<defs>
			<clipPath id="contribute_svg__a">
				<path fill="#fff" d="M0 0h32v32H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgContribute;
