// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgHsa = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 32 32"
		fill="none"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.752 11.143a.92.92 0 0 1-.917-.917V.917A.92.92 0 0 1 9.752 0h12.495a.92.92 0 0 1 .917.917v6.526a.92.92 0 0 1-.917.917.92.92 0 0 1-.917-.917V1.834H10.67v8.392a.92.92 0 0 1-.918.917ZM22.252 32H9.756a.92.92 0 0 1-.917-.918v-6.401a.92.92 0 0 1 .917-.918.92.92 0 0 1 .917.918v5.484h10.66v-8.392a.92.92 0 0 1 .917-.917.92.92 0 0 1 .918.917v9.31a.92.92 0 0 1-.918.917Zm3.182-9.31h5.649a.92.92 0 0 0 .917-.917V10.231a.92.92 0 0 0-.917-.917h-8.831a.92.92 0 0 0-.917.917.92.92 0 0 0 .917.917h7.914v9.708h-4.732a.92.92 0 0 0-.917.917.92.92 0 0 0 .917.918Zm-15.681 0H.917A.92.92 0 0 1 0 21.774V10.231a.92.92 0 0 1 .917-.917h5.796a.92.92 0 0 1 .917.917.92.92 0 0 1-.917.917H1.834v9.708h7.919a.92.92 0 0 1 .917.917.92.92 0 0 1-.917.918Zm9.064-2.329a3.163 3.163 0 0 0 .922-2.027c.064-1.49-.89-2.54-2.843-3.1l-1.27-.408c-.812-.225-1.22-.624-1.22-1.188 0-.683.628-1.183 1.49-1.183a1.31 1.31 0 0 1 1.467 1.046l.028.16h2.031l-.018-.21a2.97 2.97 0 0 0-.816-1.835 3.003 3.003 0 0 0-1.797-.899v-.56a.854.854 0 0 0-.244-.591.765.765 0 0 0-.27-.183.756.756 0 0 0-.316-.07c-.11 0-.22.024-.326.065-.1.041-.192.1-.27.179a.909.909 0 0 0-.184.27.853.853 0 0 0-.064.321v.57a3.104 3.104 0 0 0-1.93.976 3.093 3.093 0 0 0-.812 2.004c0 1.417.876 2.384 2.682 2.953l1.275.349c.981.284 1.39.678 1.371 1.325-.018.747-.674 1.238-1.674 1.238a1.59 1.59 0 0 1-1.045-.266 1.561 1.561 0 0 1-.628-.876l-.037-.142H12.26l.019.206c.082.72.408 1.385.921 1.89a3.14 3.14 0 0 0 1.912.88v.578a.82.82 0 0 0 .238.592c.079.082.17.142.271.183.101.046.211.069.321.069a.853.853 0 0 0 .592-.243.86.86 0 0 0 .252-.591v-.565a3.156 3.156 0 0 0 2.031-.917Z"
			fill={props.color || `#111827`}
		/>
	</svg>
);
export default SvgHsa;
