/* eslint-disable max-lines */
import React, { SVGProps, useEffect, useState } from 'react';
import classes from './ExpenseDetailPage.module.scss';

import {
	Incomplete,
	ChevronClose,
	Expense,
	Doc,
	Setting,
	Attachment,
	Payback,
	Message,
	Reimburse,
	Car
} from '../../components/icons';
import { StyledLink, StyledLinkSizes } from '../../components/common/Link';
import { Button, ButtonTypes, ButtonSizes } from '../../components/common/Button';
import pdfImage from '../../assets/img/pdf.png';
import logo from '../../assets/img/IHLogo.svg';
import { Banner, BannerTypes } from '../../components/common/Banner';
import {
	ExpenseStatusType,
	ExpenseActionType,
	ExpenseType,
	ExpenseMode,
	ReimburseTo,
	AccountType,
	LEGAL_COPY,
	ReimburseExpenseType
	// ExpenseMode
} from '../../constants/common';
import { getDecimalVal } from '../../utils/numberUtil';
import Modal from 'react-modal';
import drive from '../../assets/img/Travel.svg';
import { dateUtil } from '../../utils/dateUtil';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { expenseApi } from '../../services/expenseService';
import { Loader } from '../../components/common/Loader';

import { ContactUs } from '../../components/ContactUs';
import {
	ZendaAuditInfo,
	DeleteExpense,
	DeleteAttachment,
	CancelExpense
} from '../../components/CreateExpense';
import successImage from '../../assets/img/ThumbsUpLight.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountSummary } from '../../store/accountSummarySlice';

// import { expenseDetails as expDetails } from '../../constants/expense';

export const ExpenseDetailPage = () => {
	const [isLearnMore, setLearnMore] = useState<boolean>(false);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [expenseDetails, setExpenseDetails] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [statusStyleType, setStatusStyleType] = useState<{
		bannerType: string;
		statusType: string;
		bannerIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	}>({
		bannerType: BannerTypes.INFO,
		statusType: '',
		bannerIcon: Doc
	});
	const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
	const [zendaAudit, setZendaAudit] = useState<boolean>(false);
	const [reimburseModal, setReimburseModal] = useState<boolean>(false);

	const [isReimburseLoading, setIsReimburseLoading] = useState(false);
	const [isReimbursed, setIsExpenseReimbursed] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [documentToDelete, setDocumentToDelete] = useState<null | {
		delete_file_list: Array<string>;
	}>(null);
	const [isCancelExpenseModalOpen, setIsCancelExpenseModalOpen] = useState<boolean>(false);

	const dispatch = useDispatch<any>();
	const summaryData = useSelector((state: any) => state.summaryData);
	const isClearRoute = searchParams.get('isClearRoute') || false;
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '560px',
			transform: 'translate(-50%, -50%)',
			padding: '56px 24px',
			backgroundColor: '#001b47',
			borderColor: '#001b47'
		}
	};
	const customModalStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '560px',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			border: 'none',
			borderRadius: '12px'
		}
	};

	const setStyleByStatus = (expense: any) => {
		if (
			[
				ExpenseStatusType.NEEDS_MORE_INFO,
				ExpenseStatusType.RECEIPT_RECOMMENDED,
				ExpenseStatusType.AWAITING_REVIEW,
				ExpenseStatusType.ELIGIBLE_FOR_REIMBURSEMENT,
				ExpenseStatusType.APPROVED
			].includes(expense.status.key)
		) {
			setStatusStyleType(lastStyle => {
				return { ...lastStyle, bannerType: BannerTypes.WARNING };
			});
		} else if (
			[ExpenseStatusType.DENIED, ExpenseStatusType.PARTIALLY_APPROVED].includes(expense.status.key)
		) {
			setStatusStyleType(lastStyle => {
				return { ...lastStyle, bannerType: BannerTypes.NEGATIVE };
			});
		}

		// setting status button color

		if (
			[ExpenseStatusType.AWAITING_REVIEW, ExpenseStatusType.SETTLED].includes(expense.status.key)
		) {
			setStatusStyleType(lastStyle => {
				return { ...lastStyle, statusType: classes['gray-button'] };
			});
		} else if (
			[ExpenseStatusType.APPROVED, ExpenseStatusType.ELIGIBLE_FOR_REIMBURSEMENT].includes(
				expense.status.key
			)
		) {
			setStatusStyleType(lastStyle => {
				return { ...lastStyle, statusType: classes['success-button'] };
			});
		} else if (
			[ExpenseStatusType.DENIED, ExpenseStatusType.PARTIALLY_APPROVED].includes(expense.status.key)
		) {
			setStatusStyleType(lastStyle => {
				return { ...lastStyle, statusType: classes['danger-button'] };
			});
		} else if (
			[ExpenseStatusType.RECEIPT_RECOMMENDED, ExpenseStatusType.NEEDS_MORE_INFO].includes(
				expense.status.key
			)
		) {
			setStatusStyleType(lastStyle => {
				return { ...lastStyle, statusType: classes['warning-button'] };
			});
		}

		// banner icon

		if (ExpenseStatusType.PARTIALLY_APPROVED == expense.status.key) {
			setStatusStyleType(lastStyle => {
				return { ...lastStyle, bannerIcon: Expense };
			});
		} else if (ExpenseStatusType.DENIED == expense.status.key) {
			setStatusStyleType(lastStyle => {
				return { ...lastStyle, bannerIcon: Incomplete };
			});
		} else if (ExpenseStatusType.AWAITING_REVIEW == expense.status.key) {
			setStatusStyleType(lastStyle => {
				return { ...lastStyle, bannerIcon: Setting };
			});
		}
	};

	const getExpenseDetails = (expenseId: string) => {
		setIsLoading(true);
		expenseApi
			.getExpense(expenseId)
			.then((response: any) => {
				setStyleByStatus(response.data);
				setExpenseDetails(response.data);
				setIsLoading(false);
			})
			.catch(() => {
				navigate('/apps/expenses/summary');
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		const expenseId = searchParams.get('id');
		if (!expenseId) {
			navigate('/apps/expenses/summary');
			return;
		}
		// (window as any).gtag('event', 'screen_view', {
		// 	app_name: 'Zenda-web',
		// 	screen_name: 'Expense'
		// });
		getExpenseDetails(expenseId);
		dispatch(getAccountSummary());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	const getHsaAmount = () => {
		const hsaAccount = summaryData.data.summary.find(
			(account: { type: string }) => account.type == AccountType.HSA
		);
		return hsaAccount.amount;
	};

	const getActions = () => {
		const actionList: Array<JSX.Element> = [];
		expenseDetails?.action_list?.forEach((action: { action: string; text: string }) => {
			if (
				action.action == ExpenseActionType.ATTACH_DOCUMENTS &&
				ExpenseStatusType.ELIGIBLE_FOR_REIMBURSEMENT == expenseDetails.status.key
			) {
				return;
			}
			switch (action.action) {
				case ExpenseActionType.ATTACH_DOCUMENTS:
					actionList.unshift(
						<div key={`action-${action.text}`} className="d-flex flex-column py-16">
							<Button
								onClick={() => {
									navigate(
										`/apps/expense/attach-documents?id=${expenseDetails?.expense_id}&status=${expenseDetails?.status?.key}`
									);
								}}
							>
								<span className="d-flex align-items-center gap-12">
									<Attachment color={'#fff'} height={18} width={18} />
									{action.text}
								</span>
							</Button>
						</div>
					);
					break;
				case ExpenseActionType.SUBMIT:
					actionList.unshift(
						<div key={`action-${action.text}`} className="d-flex flex-column py-16">
							<Button>{action.text}</Button>
						</div>
					);
					break;
				case ExpenseActionType.CANCEL:
					actionList.unshift(
						<div key={`action-${action.text}`} className="d-flex flex-column py-16">
							<Button
								type={ButtonTypes.SECONDARY}
								onClick={() => {
									setIsCancelExpenseModalOpen(true);
								}}
							>
								{action.text}
							</Button>
						</div>
					);
					break;
				case ExpenseActionType.CONTACT_US:
					actionList.push(
						<div key={`action-${action.text}`} className="d-flex justify-content-center py-8">
							<StyledLink
								onClick={() => {
									setIsContactUsOpen(true);
								}}
							>
								<span className="d-flex align-items-center gap-12">
									<Message color={'#2a518c'} height={18} width={18} />
									<span className="text-base-350">{action.text}</span>
								</span>
							</StyledLink>
						</div>
					);
					break;
				case ExpenseActionType.PAYBACK:
					actionList.unshift(
						<div key={`action-${action.text}`} className="d-flex flex-column py-16">
							<Button
								type={
									expenseDetails?.action_list.filter(
										(act: { action: string; text: string }) =>
											act.action == ExpenseActionType.ATTACH_DOCUMENTS
									).length > 0
										? ButtonTypes.SECONDARY
										: ButtonTypes.PRIMARY
								}
								onClick={() => {
									navigate(`/apps/expense/pay-back?id=${expenseDetails?.expense_id}`);
								}}
							>
								<span className="d-flex align-items-center gap-12">
									<Payback
										color={
											expenseDetails?.action_list.filter(
												(act: { action: string; text: string }) =>
													act.action == ExpenseActionType.ATTACH_DOCUMENTS
											).length > 0
												? '#001b47'
												: '#fff'
										}
										height={24}
										width={24}
									/>
									{action.text}
								</span>
							</Button>
						</div>
					);
					break;
				case ExpenseActionType.REIMBURSE:
					actionList.unshift(
						<div key={`action-${action.text}`} className="d-flex flex-column py-16">
							<Button
								onClick={() => {
									setReimburseModal(true);
								}}
								isDisabled={getHsaAmount() < expenseDetails.reimbursement_amount}
							>
								<span className="d-flex align-items-center gap-12">
									<Reimburse color={'#fff'} width={28} height={28} />
									{action.text}
								</span>
							</Button>
							{getHsaAmount() < expenseDetails.reimbursement_amount && (
								<p className="text-center text-base-325 py-8">
									You cannot reimburse this expense until you have a sufficient HSA balance.
								</p>
							)}
						</div>
					);
					break;
				case ExpenseActionType.DELETE:
					actionList.push(
						<div key={`action-${action.text}`} className="d-flex justify-content-center py-8">
							<StyledLink
								onClick={() => {
									setIsDeleteModalOpen(true);
								}}
							>
								<span className="text-base-350">{action.text}</span>
							</StyledLink>
						</div>
					);
					break;
				case ExpenseActionType.FIX_MISTAKEN_WITHDRAWAL:
					actionList.push(
						<div key={`action-${action.text}`} className="d-flex justify-content-center py-8">
							<StyledLink
								onClick={() => {
									navigate(`/apps/expense/mistaken-withdrawal?id=${expenseDetails?.expense_id}`);
								}}
							>
								<span className="text-base-350">{action.text}</span>
							</StyledLink>
						</div>
					);
					break;
				default:
					return null;
			}
		});

		return actionList;
	};

	const reimburseExpense = () => {
		setIsReimburseLoading(true);
		expenseApi
			.reImburseExpense(expenseDetails.expense_id, {
				type: ExpenseType.REIMBURSE,
				sent_at: dateUtil.getEpochTime()
			})
			.then(() => {
				setIsReimburseLoading(false);
				setReimburseModal(false);
				setIsExpenseReimbursed(true);
			})
			.catch(() => {
				setReimburseModal(false);
				setIsReimburseLoading(false);
			});
	};

	if (isLoading || summaryData.loading) {
		return <Loader />;
	}

	if (isReimbursed) {
		return (
			<>
				<div
					className={`d-flex justify-content-center align-items-center w-100 mt-64 ${classes['expense-header']}`}
				>
					<p className={classes['header-text']}>Expense Details</p>
				</div>
				<div className="d-flex flex-column align-items-center mt-128">
					<p className={`text-h1-325`}>Reimbursement Complete!</p>
					<img
						className={`${classes['success-image']} py-16`}
						width={236}
						height={236}
						src={successImage}
					/>

					{expenseDetails?.expense_mode?.toLowerCase() == ExpenseMode.MANUAL &&
						expenseDetails?.account_breakdown?.reimbursement_details?.reimbursement_to_account !=
							ReimburseTo.EVERYDAY && (
							<p>
								<span className="text-base-700">Please allow 3-5 business days</span> for the money
								to transfer to your{' '}
								<span className={classes['text-capitalize']}>
									{expenseDetails?.account_breakdown?.reimbursement_details?.reimbursement_to_account.toLowerCase()}
								</span>
								.
							</p>
						)}
					{expenseDetails?.expense_mode?.toLowerCase() == ExpenseMode.MANUAL &&
						expenseDetails?.account_breakdown?.reimbursement_details?.reimbursement_to_account ==
							ReimburseTo.EVERYDAY && (
							<p className="text-base-350">
								${getDecimalVal(expenseDetails?.reimbursement_amount)} was transferred from your HSA
								to your Everyday account.
							</p>
						)}

					{expenseDetails.expense_mode.toLowerCase() == ExpenseMode.TRANSACTION && (
						<p className="text-base-350">
							${getDecimalVal(expenseDetails?.reimbursement_amount)} was transferred from your HSA
							to your Everyday account.
						</p>
					)}
					<hr />
					<div className="row w-100  justify-content-center">
						<div className=" d-flex flex-column cl-lg-4 cl-md-12 pt-24">
							<Button
								onClick={e => {
									e.preventDefault();
									setIsExpenseReimbursed(false);
									getExpenseDetails(searchParams.get('id') as string);
								}}
							>
								View Expense
							</Button>
						</div>

						<div className=" d-flex flex-column cl-lg-4  cl-md-12 pt-24">
							<Button
								type={ButtonTypes.SECONDARY}
								onClick={() => {
									navigate('/apps/expenses/summary');
								}}
							>
								Return to Dashboard
							</Button>
						</div>
					</div>
				</div>
			</>
		);
	}

	const showExpenseAmountSection = () => {
		return (
			expenseDetails?.expense_details?.expense_type == ReimburseExpenseType.TRAVEL &&
			expenseDetails?.account_breakdown?.reimbursement_details?.reimbursement_from_accounts
				?.length == 1 &&
			expenseDetails?.account_breakdown?.reimbursement_details?.reimbursement_from_accounts[0].account.toLowerCase() ==
				AccountType.HSA.toLowerCase()
		);
	};
	return (
		<>
			<Modal
				isOpen={isLearnMore}
				style={customStyles}
				onRequestClose={() => {
					setLearnMore(false);
				}}
				contentLabel="Learn More Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<React.Fragment>
					<div
						onClick={() => {
							setLearnMore(false);
						}}
						className="modal-close-btn-container"
					>
						<Incomplete></Incomplete>
					</div>
					<div className={`d-flex justify-content-center text-h2-325 text-yellow`}>
						Did you know?
					</div>
					<br />
					<div className={`d-flex justify-content-center`}>
						<img src={drive} width="200" />
					</div>
					<br />
					<div className={`text-base-350 py-8 text-white`}>
						The IRS allows you to use HSA/FSA dollars to pay for transportation costs for traveling
						to receive a health related service or product.
						<br /> <br /> You can include:
						<ul className={`text-sm-325`}>
							<li>Round trip mileage</li>
							<li>Ride share, taxi or public transportation costs</li>
							<li>Parking fees or tolls incurred</li>
							<li>
								Plane fare or hotel costs when the trip was primarily for, and essential to, medical
								care
							</li>
						</ul>
					</div>
					<br />

					<div className={` d-flex justify-content-center`}>
						<Button
							type={ButtonTypes.TERTIARY}
							onClick={() => {
								setLearnMore(false);
							}}
						>
							CONTINUE
						</Button>
					</div>
				</React.Fragment>
			</Modal>
			<ContactUs
				openModal={isContactUsOpen}
				closeModal={() => {
					setIsContactUsOpen(false);
				}}
				eventPage="Expense-Details"
			/>
			<Modal
				isOpen={zendaAudit}
				style={customModalStyles}
				onRequestClose={() => {
					setZendaAudit(false);
				}}
				contentLabel="Zenda Audit Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<React.Fragment>
					<ZendaAuditInfo
						transactionData={expenseDetails}
						closeModal={() => {
							setZendaAudit(false);
						}}
					/>
				</React.Fragment>
			</Modal>

			<Modal
				isOpen={reimburseModal}
				style={customStyles}
				onRequestClose={() => {
					setReimburseModal(false);
				}}
				contentLabel="Reimburse Expense"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<React.Fragment>
					<>
						{isReimburseLoading ? (
							<Loader />
						) : (
							<>
								<div
									onClick={() => {
										setReimburseModal(false);
									}}
									className="modal-close-btn-container"
								>
									<Incomplete></Incomplete>
								</div>
								<p className="text-h4-325 text-center text-yellow">Confirm Reimbursement</p>

								{expenseDetails?.expense_mode?.toLowerCase() == ExpenseMode.MANUAL && (
									<p className="py-8 px-8 text-center text-base-350 text-white">
										${getDecimalVal(expenseDetails?.reimbursement_amount)} will transfer from your
										HSA to your
										{` `}
										<span className={classes['text-capitalize']}>
											{expenseDetails?.account_breakdown?.reimbursement_details?.reimbursement_to_account.toLowerCase()}
										</span>
										{` `}
										account.
									</p>
								)}

								{expenseDetails.expense_mode.toLowerCase() == ExpenseMode.TRANSACTION && (
									<p className="py-8 px-8 text-center text-base-350 text-white">
										${getDecimalVal(expenseDetails.reimbursement_amount)} will transfer from your
										HSA to your Everyday account.
									</p>
								)}
								<div className={`d-flex flex-column`}>
									<div className=" d-flex flex-column pt-24">
										<Button
											type={ButtonTypes.TERTIARY}
											onClick={e => {
												e.preventDefault();
												reimburseExpense();
											}}
										>
											Confirm Reimbursement
										</Button>
									</div>
									<div className=" d-flex flex-column  pt-24">
										<Button
											onClick={() => {
												setReimburseModal(false);
											}}
										>
											Nevermind
										</Button>
									</div>
								</div>
							</>
						)}
					</>
				</React.Fragment>
			</Modal>
			<DeleteExpense
				isOpen={isDeleteModalOpen}
				onSuccess={() => {
					setIsDeleteModalOpen(false);
					navigate('/apps/expenses/summary');
				}}
				onCancel={() => {
					setIsDeleteModalOpen(false);
				}}
				expenseId={searchParams.get('id') as string}
			/>
			<CancelExpense
				isOpen={isCancelExpenseModalOpen}
				onSuccess={() => {
					setIsCancelExpenseModalOpen(false);
					navigate('/apps/expenses/summary');
				}}
				onCancel={() => {
					setIsCancelExpenseModalOpen(false);
				}}
				expenseDetails={expenseDetails}
				expenseId={searchParams.get('id') as string}
			/>
			<DeleteAttachment
				isOpen={!!documentToDelete}
				onSuccess={() => {
					setDocumentToDelete(null);
					getExpenseDetails(searchParams.get('id') as string);
				}}
				onCancel={() => {
					setDocumentToDelete(null);
				}}
				expenseId={searchParams.get('id') as string}
				attachment={documentToDelete}
			/>
			<div className="mt-64">
				<div className="d-flex align-items-center">
					<div
						className={`d-flex align-items-center ${classes['close-icon']}  px-4`}
						onClick={() => {
							if (isClearRoute) {
								navigate('/apps/expenses/summary');
							} else {
								navigate(-1);
							}
						}}
					>
						<ChevronClose color="#facc15" />
					</div>
					<div
						className={`d-flex justify-content-center align-items-center w-100 ${classes['expense-header']}`}
					>
						<p className={classes['header-text']}>Expense Details</p>
					</div>
				</div>

				<div className={`d-flex align-items-center justify-content-between py-16`}>
					<p className={`text-base-325 ${classes['text-white']}`}>
						{expenseDetails?.claim_id && <>Claim #{expenseDetails?.claim_id}</>}
					</p>
					<div className={`px-16 ${statusStyleType?.statusType}`}>
						<Button size={ButtonSizes.COMPACT}>
							<span className={` ${classes['status-button']} `}>
								{expenseDetails?.status?.value}
							</span>
						</Button>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column justify-content-center mt-32">
				{expenseDetails?.comment_section?.header_comments
					.filter((comment: string) => !!comment)
					.map((comment: string, index: number) => {
						if (
							index == 1 &&
							expenseDetails?.comment_section?.header_comments[0].includes('mistaken withdrawal')
						) {
							return (
								<p key={`comment-${index}`} className="d-flex text-base-325 text-primary my-4">
									<span>Date Submitted : </span>
									<span className="ml-8">{comment}</span>
								</p>
							);
						} else if (
							comment
								.toLowerCase()
								.match('You manually created this travel expense for your'.toLowerCase())
						) {
							return (
								<p className="text-base-325 text-primary">
									You manually created this travel expense for your{' '}
									<span className="text-base-325">
										<StyledLink
											onClick={() =>
												navigate(`/apps/expense?id=${expenseDetails.parent_expense_id}`)
											}
										>
											{comment?.replace('You manually created this travel expense for your', '')}
										</StyledLink>{' '}
									</span>
								</p>
							);
						} else {
							return (
								<p key={`comment-${index}`} className="text-base-325 text-primary">
									{comment}
								</p>
							);
						}
					})}

				<div className="row mt-32">
					<div className={`cl-md-6 cl-12 ${classes.order2}`}>
						{expenseDetails?.comment_section?.header_comments ? <hr className="mb-32" /> : ''}

						{!!expenseDetails.submitted_on && (
							<div className="d-flex align-items-center">
								<p className={'text-base-700 text-primary'}>Submitted:</p>
								<p className="px-8 text-base-325 text-primary">
									{dateUtil.getFormattedDate(
										Number(expenseDetails.submitted_on) * 1000,
										'MMMM DD, YYYY'
									)}
								</p>
							</div>
						)}

						{!!expenseDetails.approved_on && (
							<div className="d-flex align-items-center">
								<p className={'text-base-700 text-primary'}>Approved on:</p>
								<p className="px-8 text-base-325 text-primary">
									{dateUtil.getFormattedDate(
										Number(expenseDetails.approved_on) * 1000,
										'MMMM DD, YYYY'
									)}
								</p>
							</div>
						)}

						{!!expenseDetails.reviewed_on && (
							<div className="d-flex align-items-center">
								<p className={'text-base-700 text-primary'}>Reviewed on:</p>
								<p className="px-8 text-base-325 text-primary">
									{dateUtil.getFormattedDate(
										Number(expenseDetails.reviewed_on) * 1000,
										'MMMM DD, YYYY'
									)}
								</p>
							</div>
						)}

						{!!expenseDetails.paid_back_on && (
							<div className="d-flex align-items-center">
								<p className={'text-base-700 text-primary'}>Paid back on:</p>
								<p className="px-8 text-base-325 text-primary">
									{dateUtil.getFormattedDate(
										Number(expenseDetails.paid_back_on) * 1000,
										'MMMM DD, YYYY'
									)}
								</p>
							</div>
						)}

						<div className="mt-16">
							{expenseDetails?.expense_mode?.toLowerCase() == ExpenseMode.TRANSACTION && (
								<p className={`text-base-700 mb-16 text-primary`}>Expense Summary</p>
							)}

							{showExpenseAmountSection() ? null : (
								<>
									<div className="d-flex justify-content-between py-4">
										<p className="text-base-700 text-primary">
											{expenseDetails?.expense_mode?.toLowerCase() == ExpenseMode.MANUAL
												? 'Expense Amount'
												: 'Transaction Amount'}
										</p>
										<p className="text-base-700 text-primary">
											${getDecimalVal(expenseDetails?.expense_summary?.total_amount)}
										</p>
									</div>
									<div className="d-flex justify-content-between py-4">
										<p className="text-base-325 text-primary">Eligible Amount</p>
										<p className="text-base-325 text-primary">
											{expenseDetails?.expense_summary?.eligible_amount == null
												? 'Pending Review'
												: `$${getDecimalVal(expenseDetails?.expense_summary?.eligible_amount)}`}
										</p>
									</div>
									<div className="d-flex justify-content-between py-4">
										<p className="text-base-325 text-primary">Ineligible Amount</p>
										<p className="text-base-325 text-primary">
											{expenseDetails?.expense_summary?.ineligible_amount == null
												? 'Pending Review'
												: `$${getDecimalVal(expenseDetails?.expense_summary?.ineligible_amount)}`}
										</p>
									</div>
									<hr className="my-32" />
								</>
							)}

							{expenseDetails?.account_breakdown?.has_reimbursement && (
								<>
									<p className={'text-base-700 text-primary'}>Account Breakdown</p>

									<div className="pt-16">
										{expenseDetails?.account_breakdown?.reimbursement_details?.reimbursement_from_accounts?.map(
											(account: any, index: number) => {
												return (
													<div
														key={`reimburse-from-${index}`}
														className="d-flex justify-content-between py-4"
													>
														<p className="text-base-325 text-primary">From {account.account}</p>
														<p className="text-base-325 text-primary">
															${getDecimalVal(account.amount)}
														</p>
													</div>
												);
											}
										)}

										<div className="d-flex justify-content-between py-4">
											<p className="text-base-325 text-primary">To</p>
											<p className="text-base-325 text-primary">
												{
													expenseDetails.account_breakdown.reimbursement_details
														.reimbursement_to_account
												}
											</p>
										</div>
									</div>
									<div className="d-flex justify-content-between">
										<p className="text-base-700 text-primary">Total Reimbursement</p>
										<p className="text-base-700 text-primary">
											$
											{getDecimalVal(
												expenseDetails.account_breakdown.reimbursement_details
													.total_reimbursement_amount
											)}
										</p>
									</div>

									<hr className="my-32" />
								</>
							)}

							{!expenseDetails?.account_breakdown?.has_reimbursement && (
								<>
									<p className={'text-base-700 text-primary'}>Account Breakdown</p>

									<div className="pt-16">
										{expenseDetails?.account_breakdown?.payment_details?.paid_from_accounts?.map(
											(account: any, index: number) => {
												return (
													<div
														key={`reimburse-from-${index}`}
														className="d-flex justify-content-between py-4"
													>
														<p className="text-primary text-base-325">From {account.account}</p>
														<p className="text-primary text-base-325">
															${getDecimalVal(account.amount)}
														</p>
													</div>
												);
											}
										)}
										{expenseDetails?.account_breakdown?.payment_details?.paid_back_to_accounts?.map(
											(account: any, index: number) => {
												return (
													<div
														key={`reimburse-tp-${index}`}
														className="d-flex justify-content-between"
													>
														<p className="text-primary text-base-325">
															Paid back to {account.account}
														</p>
														<p className="text-primary text-base-325">
															${getDecimalVal(account.amount)}
														</p>
													</div>
												);
											}
										)}
									</div>
									<div className="d-flex justify-content-between">
										<p className="text-base-700 text-primary">Total Expense</p>
										<p className="text-base-700 text-primary">
											$
											{getDecimalVal(
												expenseDetails?.account_breakdown?.payment_details?.total_expense_amount
											)}
										</p>
									</div>

									<hr className="my-32" />
								</>
							)}

							<p className={'text-base-700 text-primary'}>Expense Details</p>
							<div className="py-16">
								<div className="d-flex justify-content-between py-4">
									<p className="text-base-325 text-primary">Expense Type</p>
									<p className="text-base-325 text-primary">
										{expenseDetails?.expense_details?.expense_type}
									</p>
								</div>
								<div className="d-flex justify-content-between py-4">
									<p className="text-base-325 text-primary">
										{expenseDetails?.expense_mode?.toLowerCase() == ExpenseMode.TRANSACTION
											? 'Transaction Date'
											: 'Date of Service'}
									</p>
									{expenseDetails?.expense_details?.transaction_date ? (
										<p className="text-base-325 text-primary">
											{dateUtil.getFormattedDate(
												expenseDetails?.expense_details?.transaction_date * 1000,
												'MM/DD/YYYY'
											)}
										</p>
									) : (
										<p className="text-base-325 text-primary">
											{dateUtil.getFormattedDate(
												expenseDetails?.expense_details?.start_date * 1000,
												'MM/DD/YYYY'
											)}
											&nbsp;-&nbsp;
											{dateUtil.getFormattedDate(
												expenseDetails?.expense_details?.end_date * 1000,
												'MM/DD/YYYY'
											)}
										</p>
									)}
								</div>
								{expenseDetails?.expense_details?.expense_type !== ReimburseExpenseType.TRAVEL && (
									<div className="d-flex justify-content-between py-4">
										<p className="text-base-325 text-primary">Merchant</p>
										<p className="text-base-325 text-primary">
											{expenseDetails?.expense_details?.merchant}
										</p>
									</div>
								)}
								{expenseDetails?.expense_details?.expense_type !== ReimburseExpenseType.TRAVEL && (
									<div className="d-flex justify-content-between py-4">
										<p className="text-base-325 text-primary">Expense for</p>
										<p className="text-base-325 text-primary">
											{expenseDetails?.expense_details?.expense_for}
										</p>
									</div>
								)}

								{expenseDetails?.expense_details?.expense_type == ReimburseExpenseType.TRAVEL && (
									<div className="d-flex justify-content-between py-4">
										<p className="text-primary text-base-325">
											Mileage ({expenseDetails?.travel_expense_details.mileage}mi * $
											{getDecimalVal(expenseDetails?.travel_expense_details?.cost_per_mile)})
										</p>
										<p className="text-primary text-base-325">
											$
											{getDecimalVal(
												expenseDetails?.travel_expense_details?.mileage *
													expenseDetails?.travel_expense_details?.cost_per_mile
											)}
										</p>
									</div>
								)}
								{expenseDetails?.expense_details?.expense_type == ReimburseExpenseType.TRAVEL && (
									<div className="d-flex justify-content-between py-4">
										<p className="text-primary text-base-325">Other Expenses</p>
										<p className="text-primary text-base-325">
											$
											{getDecimalVal(
												expenseDetails?.travel_expense_details?.other_travel_expense_amount
											)}
										</p>
									</div>
								)}

								{expenseDetails?.expense_details?.expense_type == ReimburseExpenseType.TRAVEL && (
									<div className="d-flex justify-content-between py-4 ">
										<p className="text-base-700 text-primary">Total Expenses</p>
										<p className="text-base-700 text-primary">
											$
											{getDecimalVal(
												expenseDetails?.travel_expense_details?.total_travel_expense_amount
											)}
										</p>
									</div>
								)}

								<div className="d-flex justify-content-between py-4">
									<p className="text-base-325 text-primary">Notes</p>
									<p className={`${classes['notes-section']} text-primary`}>
										{expenseDetails?.expense_details?.notes}
									</p>
								</div>
							</div>
							{expenseDetails.zenda_guarantee && (
								<div
									className={`d-flex px-16 py-12 justify-content-between ${classes['zenda-audit-card']}`}
								>
									<div className="d-flex align-items-center gap-20">
										<img src={logo} width={24} height={21} />
										<p className="text-base-700">Audit Protection</p>
									</div>
									<div>
										<p
											onClick={() => {
												setZendaAudit(true);
											}}
											className={`${classes['zenda-audit-link']}`}
										>
											View
										</p>
									</div>
								</div>
							)}
							{/* <p className="d-flex justify-content-center">
								<StyledLink>Edit Details</StyledLink>
							</p> */}

							{expenseDetails?.expense_details?.expense_type !== ReimburseExpenseType.TRAVEL &&
								(expenseDetails.has_travel_expense ||
									expenseDetails.action_list.some(
										(action: { action: string }) =>
											action.action == ExpenseActionType.ADD_TRAVEL_EXPENSE
									)) && (
									<>
										<hr className="my-32" />
										<div className="d-flex justify-content-between">
											<p className={'text-base-700 text-primary'}>Travel Expenses</p>
											<StyledLink
												size={StyledLinkSizes.SM}
												onClick={() => {
													setLearnMore(true);
												}}
											>
												Learn More
											</StyledLink>
										</div>

										{expenseDetails.has_travel_expense && (
											<>
												<div className="py-8">
													<div className="d-flex justify-content-between py-4">
														<p className="text-primary text-base-325">
															Mileage ({expenseDetails?.travel_expense_details.mileage}mi * $
															{getDecimalVal(expenseDetails?.travel_expense_details?.cost_per_mile)}
															)
														</p>
														<p className="text-primary text-base-325">
															$
															{getDecimalVal(
																expenseDetails?.travel_expense_details?.mileage *
																	expenseDetails?.travel_expense_details?.cost_per_mile
															)}
														</p>
													</div>
													<div className="d-flex justify-content-between py-4">
														<p className="text-primary text-base-325">Other Expenses</p>
														<p className="text-primary text-base-325">
															$
															{getDecimalVal(
																expenseDetails?.travel_expense_details?.other_travel_expense_amount
															)}
														</p>
													</div>
												</div>
												<div className="d-flex justify-content-between py-4 pb-16">
													<p className="text-base-700 text-primary">Total Travel Expenses</p>
													<p className="text-base-700 text-primary">
														$
														{getDecimalVal(
															expenseDetails?.travel_expense_details?.total_travel_expense_amount
														)}
													</p>
												</div>
												<p className="pb-16 text-base-325 text-primary">
													You cannot update your travel expenses at this time.
												</p>
											</>
										)}

										{expenseDetails.action_list.some(
											(action: { action: string }) =>
												action.action == ExpenseActionType.ADD_TRAVEL_EXPENSE
										) && (
											<>
												<p className="py-16 text-base-325 text-primary">
													Get reimbursed for any travel costs you took on for health expense!
												</p>

												<div className="d-flex justify-content-center align-items-center flex-row pb-32">
													<div
														className={`d-flex justify-content-center align-items-center ${classes['cursor-pointer']}`}
														onClick={() => {
															navigate('/apps/expense/create', {
																state: { id: expenseDetails.expense_id }
															});
														}}
													>
														<Car />
														<StyledLink>Add Travel Expenses</StyledLink>
													</div>
												</div>
											</>
										)}
									</>
								)}
							<hr className="my-32" />

							<p className={'text-base-700 text-primary'}>Documentation</p>
							{!expenseDetails?.allow_edit_documents && (
								<p className="py-16 text-primary text-base-325">
									{/* You can upload supporting documents such as an itemized receipt, invoice or
									explanation of benefits (EOB) as proof the expense is eligible. */}
									You are unable to update documentation at this time.
								</p>
							)}
							{expenseDetails?.allow_edit_documents && (
								<p className="text-base-325 py-16 text-primary">
									You can upload supporting documents such as an itemized receipt, invoice or
									explanation of benefits (EOB) as proof the expense is eligible.
								</p>
							)}

							{expenseDetails?.documents
								?.filter((document: any) => {
									const nameSplit = document.file_name.split('.');
									nameSplit.pop();
									return !nameSplit.join('.').endsWith('_thumbnail');
								})
								.map(
									(document: { file_name: string; file_type: string; presigned_url: string }) => {
										return (
											<div
												key={document.file_name}
												className="d-flex justify-content-between align-items-center py-16"
											>
												<div className="d-flex align-items-center">
													<img
														onClick={() => {
															window.open(document.presigned_url, '_blank');
														}}
														className={classes['cursor-pointer']}
														width="50"
														height="50"
														src={
															document.file_type.toLowerCase() == 'pdf'
																? pdfImage
																: document.presigned_url
														}
													></img>
													<p className="text-base-325 px-8">{document.file_name}</p>
												</div>
												{((expenseDetails.allow_edit_documents &&
													expenseDetails?.documents.length > 1) ||
													expenseDetails.status.key == ExpenseStatusType.NEEDS_MORE_INFO) && (
													<div>
														<StyledLink
															size={StyledLinkSizes.SM}
															onClick={() => {
																setDocumentToDelete({
																	delete_file_list: [document.file_name]
																});
															}}
														>
															Remove
														</StyledLink>
													</div>
												)}
											</div>
										);
									}
								)}

							{expenseDetails?.action_list?.some(
								(action: { action: ExpenseActionType }) =>
									action.action == ExpenseActionType.ATTACH_DOCUMENTS
							) &&
								ExpenseStatusType.ELIGIBLE_FOR_REIMBURSEMENT == expenseDetails.status.key && (
									<p className="d-flex justify-content-center gap-12">
										<Attachment width={18} height={18} color={'#2a518c'} />
										<StyledLink
											size={StyledLinkSizes.MD}
											onClick={() => {
												navigate(
													`/apps/expense/attach-documents?id=${expenseDetails?.expense_id}&status=${expenseDetails?.status?.key}`
												);
											}}
										>
											Attach Documents
										</StyledLink>
									</p>
								)}

							{/* {expenseDetails.allow_edit_documents && (
								<div className="d-flex justify-content-center flex-column pb-32">
									<Button
										onClick={() => {
											navigate('/apps/expense/attach-documents');
										}}
										type={ButtonTypes.SECONDARY}
									>
										Attach Documents
									</Button>
								</div>
							)} */}
							{[
								ExpenseStatusType.NEEDS_MORE_INFO,
								ExpenseStatusType.PARTIALLY_APPROVED,
								ExpenseStatusType.DENIED
							].includes(expenseDetails.status.key) && (
								<>
									<div style={{ whiteSpace: 'pre-line' }} className="text-sm-325" id="legal-copy">
										<hr className="my-32" />
										{
											LEGAL_COPY[expenseDetails?.expense_mode?.toLowerCase()][
												expenseDetails.status.key
											]
										}
									</div>
								</>
							)}
						</div>
					</div>
					<div className={`cl-md-6 cl-12`}>
						<div className="pl-32">
							<div className="mb-16">
								{expenseDetails?.comment_section?.has_alert_comment && (
									<Banner
										fill={
											![ExpenseStatusType.PARTIALLY_APPROVED, ExpenseStatusType.DENIED].includes(
												expenseDetails.status.key
											)
										}
										type={statusStyleType.bannerType}
										icon={statusStyleType.bannerIcon}
									>
										<div>
											<p className="text-base-700 py-2">
												{expenseDetails?.comment_section?.alert_comment_details?.heading}
											</p>
											<div className="text-sm-325">
												{expenseDetails?.comment_section?.alert_comment_details?.notes?.map(
													(note: string, index: number) => {
														if (note.startsWith('- ')) {
															return (
																<li key={`note-li-${index}`} className={`text-sm-325 pt-4`}>
																	{note.slice(2)}
																</li>
															);
														} else {
															return (
																<p key={`note-${index}`} className="text-sm-325">
																	{note}
																</p>
															);
														}
													}
												)}
											</div>
											{[
												ExpenseStatusType.NEEDS_MORE_INFO,
												ExpenseStatusType.PARTIALLY_APPROVED,
												ExpenseStatusType.DENIED
											].includes(expenseDetails.status.key) && (
												<div className={classes['learn-more-btn']}>
													<StyledLink
														size={StyledLinkSizes.SM}
														hideUnderLine
														href="#legal-copy"
														isOpenInNewTab={false}
													>
														<StyledLink size={StyledLinkSizes.SM}>Learn More</StyledLink>
													</StyledLink>
												</div>
											)}
										</div>
									</Banner>
								)}
							</div>

							{getActions()}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
