// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBeneficiary = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 32 36"
		{...props}
	>
		<path
			fill={props.color || `#111827`}
			d="m31.223 8.727-2.779-2.774a1827.105 1827.105 0 0 0-4.915-4.896 1.412 1.412 0 0 0-.933-.378C17.973.665 13.348.66 8.72.667H7.18a.984.984 0 0 0-1.126 1.125v1.104l-4.909.021C.368 2.92 0 3.275 0 4.032v30.079a1.101 1.101 0 0 0 .635 1.086l26.901.036.083-.046a1.114 1.114 0 0 0 .6-1.102c-.014-.364-.01-.728 0-1.092v-1.137h2.141c.912 0 1.236-.319 1.236-1.219V9.771a1.37 1.37 0 0 0-.376-1.044M7.317 31.856h19.01v1.485H1.9V4.812h4.152v25.787c0 .941.32 1.257 1.265 1.257Zm.618-29.295h13.904v6.696c0 .846.33 1.175 1.176 1.175h6.638l.044 19.525H7.935V2.561Zm15.798 5.982V4.068l4.478 4.475h-4.478Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M11.633 17.236c.084.007.17.008.252.008h.556c.16.006.32.003.48-.008a.887.887 0 0 0 .827-.812.934.934 0 0 0-.188-.7.8.8 0 0 0-.563-.301 8.343 8.343 0 0 0-1.44 0 .828.828 0 0 0-.588.316.893.893 0 0 0-.192.677.905.905 0 0 0 .856.83M12.864 23.581c-.4-.018-.8-.018-1.19 0a.933.933 0 0 0-.02 1.855c.141.01.284.013.426.007h.535c.085 0 .168 0 .252-.008a.933.933 0 0 0 .89-.926.917.917 0 0 0-.893-.933M11.587 21.317a9.548 9.548 0 0 0 1.368-.002.868.868 0 0 0 .8-.95.885.885 0 0 0-.83-.868 3.204 3.204 0 0 0-.254-.012h-.938a.798.798 0 0 0-.16.019.934.934 0 0 0-.8.895.893.893 0 0 0 .82.922M18.411 11.173V6.664a.935.935 0 0 0-1.035-1.037H11.54a.953.953 0 0 0-1.048 1.032 504.33 504.33 0 0 0 0 4.502.955.955 0 0 0 1.04 1.044H17.37a.947.947 0 0 0 1.042-1.032m-1.898-.853H12.38v-2.8h4.134v2.8ZM24.07 17.921V14.74h-4.977v3.182h-3.182V22.9h3.182v3.184h4.978v-3.186h3.181v-4.976h-3.183Zm-1.814 3.163v3.183h-1.348v-3.183h-3.183v-1.35h3.183v-3.18h1.348v3.18h3.184v1.346l-3.184.004Z"
		/>
	</svg>
);
export default SvgBeneficiary;
