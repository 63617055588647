import React from 'react';
import classes from './ExpenseTable.module.scss';
import { Hsa, ChevronClose, HsaExpense } from '../../icons';
import { useNavigate } from 'react-router-dom';
import { IExpense } from '../../../interface/common.interface';
import { ExpenseGroupType, TransactionAndExpenseDes } from '../../../constants/common';
import { StyledLink } from '../Link/StyledLink';
import { StyledLinkSizes } from '../Link/StyledLink.constants';

type ExpenseProps = {
	expenses: Array<{
		sectionName: string;
		data: Array<IExpense>;
	}>;
	hasAction?: boolean;
	dismissAction?: { title: string; icon: any; action: (expenseId: string) => void };
	confirmAction?: { title: string; icon: any; action: (expenseId: string) => void };
	openExpenseIcon?: boolean;
};

export const ExpenseTable = ({
	expenses,
	hasAction,
	dismissAction,
	confirmAction,
	openExpenseIcon = true
}: ExpenseProps) => {
	const navigate = useNavigate();
	/**
	 *
	 * @param expense
	 * @returns color of the description based on status
	 */
	const getDesClass = (expense: IExpense) => {
		switch (expense.description?.toLowerCase()) {
			case TransactionAndExpenseDes.RECEIPT_RECOMMENDED:
			case TransactionAndExpenseDes.PARTIALLY_REIMBURSED:
			case TransactionAndExpenseDes.AWAITING_REIMBURSEMENT:
			case TransactionAndExpenseDes.HOLD_AWAITING_APPROVAL:
				return classes['text-yellow'];
			case TransactionAndExpenseDes.ELIGIBLE_FOR_REIMBURSEMENT:
				return classes['green-text'];
			case TransactionAndExpenseDes.NEEDS_ADDITIONAL_INFO:
			case TransactionAndExpenseDes.ACTION_NEEDED:
				return classes['blue-text'];
			default:
				return '';
		}
	};
	return (
		<div className="d-flex flex-column">
			{expenses.map((expense, index) => {
				return (
					<div key={`expense-section-${index}`} className="mt-32">
						<div className={classes['group-name']}>{expense.sectionName}</div>
						{expense.data.map((t: any, transaction_index: number) => {
							return (
								<div key={`expense-item-${t.id}`} className={classes['detail-item']}>
									<div
										key={`expense-${transaction_index}`}
										className={` d-flex flex-row justify-content-between text-base-700 p-16`}
										onClick={() => {
											navigate(`/apps/expense?id=${t.id}`);
										}}
									>
										<div className="d-flex flex-row align-items-center">
											<div className="d-flex align-items-center pr-16">
												{t.expense_group == ExpenseGroupType.TRAVEL_POTENTIAL.toUpperCase() ? (
													<HsaExpense width={32} height={32} />
												) : (
													<Hsa width={32} height={32} />
												)}
											</div>
											<div className="d-flex flex-column align-items-start">
												<p className="text-base-700 text-primary w-100">{t.activity}</p>
												<p className={`text-sm-325 ${getDesClass(t)}`}>{t.description}</p>
											</div>
										</div>
										<div className="d-flex flex-row">
											<div className="d-flex flex-column align-items-end">
												<p className="text-base-700">{t.amount}</p>
												<p className="text-sm-325 text-secondary">{t.date}</p>
											</div>
											{openExpenseIcon && (
												<div className="pl-16 d-flex align-items-center">
													<ChevronClose />
												</div>
											)}
										</div>
									</div>
									{hasAction && (
										<div className="py-8 d-flex justify-content-around">
											<div
												className={`d-flex align-items-center justify-content-center w-50 ${classes['right-divider']}`}
											>
												<span
													className={`${classes['cursor-pointer']} d-flex justify-content-center`}
												>
													{dismissAction?.icon}
												</span>

												<StyledLink
													size={StyledLinkSizes.SM}
													onClick={() => {
														dismissAction?.action(t.id);
													}}
												>
													<span className="px-2">{dismissAction?.title}</span>
												</StyledLink>
											</div>
											<div className="d-flex align-items-center justify-content-center w-50">
												{confirmAction?.icon}
												<StyledLink
													size={StyledLinkSizes.SM}
													onClick={() => {
														confirmAction?.action(t.id);
													}}
												>
													<span className="px-2">{confirmAction?.title}</span>
												</StyledLink>
											</div>
										</div>
									)}
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};
