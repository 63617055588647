// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCard = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="#2A518C"
			d="M18.4 5.543H5.6a1.61 1.61 0 0 0-1.6 1.6v9.143a1.609 1.609 0 0 0 1.6 1.6h12.8a1.609 1.609 0 0 0 1.6-1.6V7.143a1.609 1.609 0 0 0-1.6-1.6ZM5.6 6.914h12.8a.228.228 0 0 1 .229.229V9.2H5.37V7.143a.229.229 0 0 1 .229-.229Zm12.8 9.6H5.6a.229.229 0 0 1-.229-.228V10.57H18.63v5.715a.229.229 0 0 1-.229.228Z"
		/>
		<path
			fill="#2A518C"
			d="M9.258 12.629h-1.83a.914.914 0 1 0 0 1.829h1.83a.914.914 0 1 0 0-1.83Z"
		/>
	</svg>
);
export default SvgCard;
