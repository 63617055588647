import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classes from './CreateExpenseSuccess.module.scss';
import successImage from '../../../assets/img/ThumbsUp.svg';
import { Button, ButtonTypes } from '../../common/Button';
import { useNavigate } from 'react-router-dom';
import { ReimburseExpenseType, ReimburseTo } from '../../../constants/common';
import { getDecimalVal } from '../../../utils/numberUtil';
import { changeBackground } from '../../../store/backgroundSlice';

type StepProps = {
	expenseDetails: {
		evaluateData: any;
		reimburseFormData: any;
		expenseFormData: any;
		reimburseData: {
			expense_draft_id: string;
			evaluation_type: string;
			reimburse_to: string;
			reimburse_from: string;
			is_reimburse_now: boolean;
		};
	};
};
export const CreateExpenseSuccess = ({ expenseDetails }: StepProps) => {
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(changeBackground(true));
		return () => {
			dispatch(changeBackground(false));
		};
	}, [dispatch]);

	// const summaryData = useSelector((state: any) => state.summaryData);

	return (
		<div className="d-flex flex-column align-items-center mt-64">
			{expenseDetails.reimburseData.reimburse_from == 'HSA' &&
			[ReimburseTo.EVERYDAY, ReimburseTo.EXTERNAL, ReimburseTo.CHECK].includes(
				expenseDetails.reimburseData.reimburse_to as ReimburseTo
			) ? (
				<p className={classes.title}>Transfer Successful!</p>
			) : (
				<p className={classes.title}>Submission Complete!</p>
			)}
			<img className={` my-48`} width={236} height={236} src={successImage} />
			{expenseDetails.reimburseData.reimburse_from == 'HSA' ? (
				<>
					{ReimburseTo.EVERYDAY == expenseDetails.reimburseData.reimburse_to && (
						<p className="text-base-325 py-12 text-white">
							<span className="text-base-700">
								${getDecimalVal(expenseDetails?.evaluateData?.amount_to_reimburse)} has been
								transferred
							</span>{' '}
							from your HSA to your Everyday Account.
						</p>
					)}
					{ReimburseTo.EXTERNAL == expenseDetails.reimburseData.reimburse_to && (
						<p className="text-base-325 py-12 text-white">
							<span className="text-base-700">Please allow 4-5 business days</span> for the money to
							arrive in your linked bank account.
						</p>
					)}
					{ReimburseTo.CHECK == expenseDetails.reimburseData.reimburse_to && (
						<p className="text-base-325 py-12 text-white">
							<span className="text-base-700">Please allow 6 - 10 business days</span> for the check
							to arrive at your mailing address.
						</p>
					)}
				</>
			) : (
				<>
					<p className="text-base-325 text-white">
						<span className="text-base-700">Please allow 4-5 business days</span> for us to review
						your expense and process your reimbursement.
					</p>
					<p className="text-base-325 mt-12 text-white">
						We will notify you once the expense is approved or if we need additional information.
					</p>
				</>
			)}

			{expenseDetails?.expenseFormData?.expense_type == ReimburseExpenseType.TRAVEL &&
				expenseDetails.reimburseData.reimburse_from != 'HSA' && (
					<p className="text-base-325 text-white py-12">
						If approved, your travel expense will appear as a separate transaction item in your
						history.
					</p>
				)}

			<div className="my-32">
				<Button
					type={ButtonTypes.TERTIARY}
					onClick={() => {
						navigate('/apps/expenses/summary');
					}}
				>
					Close
				</Button>
			</div>
		</div>
	);
};
