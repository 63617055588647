import React from 'react';
import { useWatch } from 'react-hook-form';
import { IconColor } from '../../../constants/common';
import { Clear, Complete } from '../../icons';
import classes from './PasswordHint.module.scss';

type PasswordHintProps = {
	control: any;
	fieldName?: string;
};
export const PasswordHint = ({ control, fieldName = 'password' }: PasswordHintProps) => {
	const password = useWatch({
		control,
		name: fieldName
	});
	const errorCondition = [
		{ text: 'Contains capital letter', error: true, pattern: '' },
		{ text: 'Contains lowercase letter', error: true, pattern: '' },
		{ text: 'Contains number', error: true, pattern: '' },
		{ text: '8 characters or more', error: true, pattern: '' },
		{ text: 'Special character required', error: true, pattern: '' }
	];
	// eslint-disable-next-line react-hooks/exhaustive-deps
	if (password || typeof password === 'string') {
		if (!password.match(/[A-Z]/g)) {
			errorCondition[0].error = true;
		} else {
			errorCondition[0].error = false;
		}
		if (!password.match(/[a-z]/g)) {
			errorCondition[1].error = true;
		} else {
			errorCondition[1].error = false;
		}
		if (!password.match(/[0-9]/g)) {
			errorCondition[2].error = true;
		} else {
			errorCondition[2].error = false;
		}
		if (!(password.length >= 8)) {
			errorCondition[3].error = true;
		} else {
			errorCondition[3].error = false;
		}
		if (!password.match(/(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=(.*))./g)) {
			errorCondition[4].error = true;
		} else {
			errorCondition[4].error = false;
		}
	}

	return (
		<div className={classes.container}>
			{errorCondition.map((value, index) => (
				<span className={classes['error-list']} key={index}>
					{value.error ? (
						<Clear height={24} width={24} color={IconColor.error} />
					) : (
						<span className={classes['tick-icon']}>
							<Complete height={16} width={16} color={'#111827'} />
						</span>
					)}{' '}
					<span> {value.text}</span>
				</span>
			))}
		</div>
	);
};
