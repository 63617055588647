// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgDoc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 32 32"
		fill="none"
		{...props}
	>
		<path
			d="M28.283 10.514 18.171.402A1.371 1.371 0 0 0 17.202 0H9.03A5.029 5.029 0 0 0 4 5.029V26.97A5.029 5.029 0 0 0 9.029 32h14.628a5.029 5.029 0 0 0 5.029-5.029V11.43a1.371 1.371 0 0 0-.403-.915ZM18.63 4.681l5.376 5.376h-5.376V4.681Zm5.028 24.576H9.03a2.286 2.286 0 0 1-2.286-2.286V5.03a2.286 2.286 0 0 1 2.286-2.286h6.857v8.686a1.39 1.39 0 0 0 1.371 1.371h8.686v14.171a2.286 2.286 0 0 1-2.286 2.286Z"
			fill={props.color || `#111827`}
		/>
	</svg>
);
export default SvgDoc;
