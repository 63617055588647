// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDeposit = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 33 33"
		{...props}
	>
		<path
			fill={props.color || `#111827`}
			d="M11.975 26.804c1.21 1.216 3.713 3.694 3.824 3.787.169.171.398.27.637.274a.823.823 0 0 0 .624-.258l.042-.04c1.26-1.25 2.516-2.503 3.767-3.761a.846.846 0 0 0 .045-1.238.847.847 0 0 0-1.239.038c-.474.468-.944.939-1.413 1.411l-.326.327c-.085.086-.17.174-.272.28l-.4.414v-4.671a.859.859 0 0 0-.843-.963.802.802 0 0 0-.577.226 1.014 1.014 0 0 0-.27.73v4.612l-.327-.3c-.058-.052-.1-.094-.147-.138l-.537-.537c-.447-.453-.912-.919-1.382-1.363a1.273 1.273 0 0 0-.593-.3.656.656 0 0 0-.117-.01.81.81 0 0 0-.707.484.841.841 0 0 0 .21.996ZM13.372 16.283a1.35 1.35 0 0 1 .982-1.441l.125-.032v-1.778l-.181.017a2.7 2.7 0 0 0-2.386 2.443h-.5a.722.722 0 0 0-.009 1.444h.488a2.725 2.725 0 0 0 2.539 2.548c1.288.063 2.191-.76 2.675-2.444l.35-1.1c.195-.7.54-1.051 1.026-1.051.591 0 1.02.541 1.02 1.286a1.135 1.135 0 0 1-.9 1.268l-.138.022v1.75l.18-.014A2.572 2.572 0 0 0 21 16.946h.484a.722.722 0 0 0 .01-1.444H21a2.654 2.654 0 0 0-2.569-2.364c-1.223 0-2.057.755-2.55 2.313l-.3 1.095c-.245.847-.586 1.2-1.141 1.183-.648-.012-1.068-.579-1.068-1.446Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M23.937 2.063H8.897a2.962 2.962 0 0 0-2.96 2.959v11.061h-3.54v5.119h28.02v-5.119h-3.523V5.022a2.962 2.962 0 0 0-2.957-2.959ZM5.929 19.548H4.05v-1.825h1.88v1.825Zm1.7-14.526a1.054 1.054 0 0 1 1.053-1.053h2.968l-.013.06A5.048 5.048 0 0 1 7.69 7.977l-.06.012V5.022Zm17.586 11.057H25.2v3.471H7.63V9.92l.042-.006a7 7 0 0 0 5.9-5.9l.007-.042h5.683l.008.042a7 7 0 0 0 5.9 5.9l.042.006.003 6.159Zm0-8.09-.06-.012a5.053 5.053 0 0 1-3.95-3.948l-.011-.06h2.969a1.053 1.053 0 0 1 1.052 1.053v2.967Zm3.552 9.735v1.817H26.89v-1.817h1.876Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M16.244 10.786a1.236 1.236 0 1 0 0-2.472 1.236 1.236 0 0 0 0 2.472Z"
		/>
	</svg>
);
export default SvgDeposit;
