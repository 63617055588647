// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgContribution = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 33 33"
		{...props}
	>
		<path
			fill={props.color || `#111827`}
			d="m31.82 23.816-3.201-7.577a.875.875 0 0 0-.8-.527h-4.536a8.751 8.751 0 1 0-13.843 0H4.903a.875.875 0 0 0-.8.527l-3.2 7.577a.881.881 0 0 0-.071.345v6.514a.875.875 0 0 0 .875.875h29.316a.875.875 0 0 0 .875-.875v-6.514a.883.883 0 0 0-.079-.345ZM16.356 3.376a7 7 0 1 1-7 7 7.01 7.01 0 0 1 7.004-7h-.004ZM5.474 17.459h5.766a8.699 8.699 0 0 0 3.662 1.536h-6.74l-.512 1.641h17.411l-.506-1.641H17.81a8.7 8.7 0 0 0 3.662-1.536h5.766l2.441 5.827H3.029l2.445-5.827ZM30.141 29.8H2.575v-4.764h27.566V29.8Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M16.36 13.483a1.35 1.35 0 0 1-1.44-.982l-.033-.125H13.11l.018.181a2.7 2.7 0 0 0 2.442 2.386v.5a.722.722 0 1 0 1.444.01v-.489a2.724 2.724 0 0 0 2.548-2.539c.063-1.288-.76-2.191-2.444-2.675l-1.1-.351c-.7-.194-1.05-.539-1.05-1.025 0-.591.54-1.02 1.285-1.02a1.135 1.135 0 0 1 1.268.9l.023.138h1.75l-.015-.18a2.572 2.572 0 0 0-2.255-2.357v-.484a.72.72 0 0 0-1.387-.285.723.723 0 0 0-.056.276v.493a2.654 2.654 0 0 0-2.364 2.569c0 1.223.754 2.057 2.312 2.55l1.095.3c.847.245 1.2.586 1.183 1.141-.017.643-.584 1.068-1.446 1.068Z"
		/>
	</svg>
);
export default SvgContribution;
