import classes from './TransactionTableV2.module.scss';
import React from 'react';
import {
	ChevronClose,
	Contribution,
	Deposit,
	EverydayExpense,
	HsaExpense,
	InterestPayment,
	MixedExpense,
	Reimbursement,
	Transfer
} from '../../icons';
import { useNavigate } from 'react-router-dom';
import { ITransaction } from '../../../interface/common.interface';
// import zIcon from '../../../assets/img/ZGaurantee.svg';
import { TransactionAndExpenseDes } from '../../../constants/common';

type TableProps = {
	sections: Array<{ sectionName: string; data: Array<ITransaction> }>;
	children?: JSX.Element | Array<JSX.Element> | string;
	type?: string;
};

export const TransactionTableV2 = ({ children, sections }: TableProps) => {
	const navigate = useNavigate();

	/**
	 *
	 * @param transaction
	 * @returns color of the description based on status
	 */
	const getDesClass = (transaction: ITransaction) => {
		switch (transaction.description.toLowerCase()) {
			case TransactionAndExpenseDes.RECEIPT_RECOMMENDED:
			case TransactionAndExpenseDes.PARTIALLY_REIMBURSED:
			case TransactionAndExpenseDes.AWAITING_REIMBURSEMENT:
			case TransactionAndExpenseDes.HOLD_AWAITING_APPROVAL:
				return classes['text-yellow'];
			case TransactionAndExpenseDes.ELIGIBLE_FOR_REIMBURSEMENT:
				return classes['green-text'];
			case TransactionAndExpenseDes.NEEDS_ADDITIONAL_INFO:
			case TransactionAndExpenseDes.ACTION_NEEDED:
				return classes['blue-text'];
			default:
				return '';
		}
	};

	const getIcons = (transaction: ITransaction) => {
		const colorProps = {};
		switch (transaction.image) {
			case 'medical':
				return <HsaExpense width={32} height={32} {...colorProps} />;
			case 'deposit':
				return <Deposit width={32} height={32} {...colorProps} />;
			case 'contribution':
				return <Contribution width={32} height={32} {...colorProps} />;
			case 'reimbursement':
				return <Reimbursement width={32} height={32} {...colorProps} />;
			case 'interest-payment':
				return <InterestPayment width={32} height={32} {...colorProps} />;
			case 'mixed':
				return <MixedExpense width={32} height={32} {...colorProps} />;
			case 'non-medical':
				return <EverydayExpense width={32} height={32} {...colorProps} />;
			case 'ach-withdrawal':
			case 'check-withdrawal':
			case 'reimbursement/deposit':
				return <Reimbursement width={32} height={32} {...colorProps} />;
			case 'investment':
				return <Transfer width={32} height={32} {...colorProps} />;
			default:
				return <EverydayExpense width={32} height={32} {...colorProps} />;
		}
	};

	return (
		<React.Fragment>
			<div style={{ overflow: 'auto' }}>
				<div className="d-flex flex-column">
					{sections.map((section, index) => {
						return (
							<div key={`transaction-section-${index}`} className={classes['transaction-section']}>
								<div className={classes['group-name']}>{section.sectionName}</div>
								{section.data.map((t: any, transaction_index: number) => {
									return (
										<div
											key={`transaction-${transaction_index}`}
											className={`${classes['detail-item']} ${
												t.show_details ? classes['details-item-cursor'] : ''
											} d-flex flex-row justify-content-between`}
											onClick={() => {
												if (t.show_details) {
													if (t.expense_id) {
														navigate(`/apps/expense?id=${t.expense_id}`);
													} else {
														navigate(`/apps/transaction?id=${t.id}`);
													}
													// navigate(`/apps/transaction?id=${t.id}`);
												}
												return;
											}}
										>
											<div className="d-flex flex-row align-items-center">
												<div className={`d-flex align-items-center pr-16`}>{getIcons(t)}</div>
												<div className="d-flex flex-column align-items-start">
													<p className={`text-base-700 w-100 text-primary `}>{t.activity}</p>
													<p
														className={`text-sm-325 d-flex text-secondary align-items-center ${getDesClass(
															t
														)}`}
													>
														{/* {t.zguarantee && (
															<img src={zIcon} width={12} height={12} className="mr-8" />
														)} */}
														{/* {isReceiptRecommended(t.description) && (
															<span className="mr-4 d-flex align-items-center">
																<Warning width={16} fill="#f8a233" color="#fff" />
															</span>
														)} */}
														{t.description}
													</p>
												</div>
											</div>
											<div className="d-flex flex-row">
												<div className="d-flex flex-column align-items-end">
													<p className={`text-base-700 text-primary`}>{t.amount}</p>
													<p className={`text-sm-325 text-secondary`}>{t.date}</p>
												</div>
												<div className="pl-16 d-flex align-items-center">
													{t.show_details ? <ChevronClose /> : <span className="px-12"></span>}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
			{children}
		</React.Fragment>
	);
};
