// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 32 33"
		fill="none"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.514 32.004H5.03A4.829 4.829 0 0 1 0 27.378V4.631A4.827 4.827 0 0 1 5.029.004h5.485a1.371 1.371 0 0 1 0 2.743H5.03a2.121 2.121 0 0 0-2.286 1.829v22.802a2.121 2.121 0 0 0 2.286 1.829h5.485a1.371 1.371 0 0 1 0 2.742v.055Zm12.8-7.314a1.352 1.352 0 0 1-.969-.402 1.371 1.371 0 0 1 0-1.939l4.974-4.973H10.514a1.371 1.371 0 0 1 0-2.743H27.32l-4.974-4.974a1.37 1.37 0 1 1 1.939-1.938l7.287 7.287.027.027a1.371 1.371 0 0 1-.033 1.971l-7.281 7.282a1.352 1.352 0 0 1-.97.402Z"
			fill={props.color || `#111827`}
		/>
	</svg>
);
export default SvgLogout;
