// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSetting = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 36 36"
		{...props}
	>
		<path
			fill={props.color || `#111827`}
			d="M17.997 24.028a6.028 6.028 0 1 1 0-12.055 6.028 6.028 0 0 1 0 12.055Zm0-8.97a2.942 2.942 0 1 0 0 5.884 2.942 2.942 0 0 0 0-5.884Z"
		/>
		<path
			fill={props.color || `#111827`}
			d="M18.12 36.003a4.565 4.565 0 0 1-4.546-4.61.988.988 0 0 0-.597-.863 1.05 1.05 0 0 0-1.152.185 4.609 4.609 0 0 1-3.21 1.337 4.65 4.65 0 0 1-3.25-1.296 4.566 4.566 0 0 1 0-6.46 1.029 1.029 0 0 0 .287-1.07 1.152 1.152 0 0 0-.987-.576 4.67 4.67 0 0 1-4.65-4.527 4.568 4.568 0 0 1 4.588-4.547.988.988 0 0 0 .864-.596 1.05 1.05 0 0 0-.185-1.152 4.609 4.609 0 0 1-1.337-3.21A4.526 4.526 0 0 1 5.24 5.367a4.568 4.568 0 0 1 6.46 0 1.193 1.193 0 0 0 1.132.288 1.235 1.235 0 0 0 .638-.987 4.67 4.67 0 0 1 4.526-4.67 4.568 4.568 0 0 1 4.527 4.608 1.07 1.07 0 0 0 .576.905 1.029 1.029 0 0 0 1.07-.226 4.609 4.609 0 0 1 3.21-1.338 4.382 4.382 0 0 1 3.25 1.297 4.567 4.567 0 0 1 0 6.46 1.194 1.194 0 0 0-.226 1.132 1.234 1.234 0 0 0 .987.637A4.671 4.671 0 0 1 36 18a4.568 4.568 0 0 1-4.609 4.526 1.07 1.07 0 0 0-.905.576 1.029 1.029 0 0 0 .226 1.07 4.609 4.609 0 0 1 1.338 3.21 4.528 4.528 0 0 1-1.296 3.25 4.57 4.57 0 0 1-6.461 0 1.028 1.028 0 0 0-1.07-.226 1.152 1.152 0 0 0-.576.988 4.67 4.67 0 0 1-4.526 4.609Zm-5.575-8.662a3.767 3.767 0 0 1 1.584.329 4.115 4.115 0 0 1 2.53 3.703 1.523 1.523 0 0 0 1.462 1.543 1.564 1.564 0 0 0 1.44-1.584 4.239 4.239 0 0 1 2.428-3.806 4.115 4.115 0 0 1 4.485.885 1.455 1.455 0 1 0 2.057-2.058 4.114 4.114 0 0 1-.864-4.485 4.114 4.114 0 0 1 3.745-2.428A1.502 1.502 0 0 0 32.934 18a1.544 1.544 0 0 0-1.584-1.44 4.32 4.32 0 0 1-3.806-2.51 4.218 4.218 0 0 1 .884-4.527 1.455 1.455 0 1 0-2.057-2.057 4.115 4.115 0 0 1-6.913-2.88 1.502 1.502 0 0 0-1.44-1.523 1.543 1.543 0 0 0-1.44 1.584 4.321 4.321 0 0 1-2.51 3.806A4.218 4.218 0 0 1 9.54 7.57a1.455 1.455 0 1 0-2.057 2.057 4.115 4.115 0 0 1 .843 4.485 4.115 4.115 0 0 1-3.703 2.53 1.523 1.523 0 0 0-1.543 1.462 1.564 1.564 0 0 0 1.584 1.44 4.237 4.237 0 0 1 3.806 2.428 4.115 4.115 0 0 1-.885 4.485 1.455 1.455 0 0 0 2.058 2.058 4.238 4.238 0 0 1 2.9-1.173Z"
		/>
	</svg>
);
export default SvgSetting;
