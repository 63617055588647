// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgExpense = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 24 24"
		fill="none"
		{...props}
	>
		<g clipPath="url(#expense_svg__a)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.703.004H20.27L20.26 0a.715.715 0 0 1 .486.214.72.72 0 0 1 .198.492v20.527a.703.703 0 0 1-.207.495l-2.067 2.068a.7.7 0 0 1-.991 0l-1.572-1.572-1.572 1.572a.7.7 0 0 1-.991 0l-1.572-1.572-1.572 1.572a.7.7 0 0 1-.991 0l-1.572-1.572-1.572 1.572a.7.7 0 0 1-.991 0l-2.068-2.068A.703.703 0 0 1 3 21.233V.709a.705.705 0 0 1 .703-.705Zm12.928 20.73 1.572 1.571h.01l1.364-1.364V1.409H4.415v19.532l1.383 1.364 1.572-1.571a.7.7 0 0 1 .991 0l1.572 1.571 1.572-1.571a.7.7 0 0 1 .991 0l1.572 1.571 1.572-1.571a.7.7 0 0 1 .991 0Zm-4.919-10.726.941.298.01-.003c1.434.414 2.136 1.182 2.086 2.284a2.326 2.326 0 0 1-.68 1.493 2.312 2.312 0 0 1-1.5.668v.411c0 .163-.063.32-.18.436a.628.628 0 0 1-.435.179.618.618 0 0 1-.615-.615v-.427a2.295 2.295 0 0 1-2.08-2.03l-.016-.153h1.51l.024.107c.072.263.236.489.462.643.222.153.495.222.765.194.734 0 1.217-.357 1.23-.91.012-.476-.286-.765-1.007-.972l-.935-.26c-1.327-.42-1.97-1.13-1.97-2.171a2.26 2.26 0 0 1 2.011-2.187V6.58a.615.615 0 1 1 1.23 0v.414c.501.06.966.292 1.317.659.351.364.565.84.602 1.346l.016.153h-1.49l-.019-.119a.973.973 0 0 0-1.076-.765c-.634 0-1.095.364-1.095.866 0 .414.301.709.894.875Z"
				fill={props.color || `#111827`}
			/>
		</g>
		<defs>
			<clipPath id="expense_svg__a">
				<path fill="#fff" d="M0 0h24v23.999H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgExpense;
