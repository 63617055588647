import React from 'react';
import classes from './IconNavLink.module.scss';
import { Link } from 'react-router-dom';
import { ChevronClose } from '../../icons';
import {
	TButtonProps,
	TIconsNavLinkContentProps,
	TIconsNavLinkProps,
	TLinkProps
} from './IconNavLink.types';
import { IconNavLinkTypes } from './IconNavLink.constants';
import { getClassNames } from '../../../utils/Css';

const IconNavLinkContent: React.FC<TIconsNavLinkContentProps> = ({
	leftIcon,
	title,
	mainSubtitle,
	additionalInfo,
	additionalSubInfo,
	hasRightArrow = true,
	arrowColor = 'black'
}) => {
	return (
		<div className={`d-flex align-items-center ${classes['btn-content']}`}>
			{/* Left Part of the button/link */}
			<div className={`${classes['btn-content-left']} d-flex align-items-center `}>{leftIcon}</div>
			{/* Center Part of the button/link */}
			<div className={`d-flex jusitfy-content-end ${classes['btn-content-center']} text-base-700`}>
				<div className={classes['center-left']}>
					<div>{title}</div>
					{mainSubtitle && <div className="text-sm-325 mt-2">{mainSubtitle}</div>}
				</div>
				{additionalInfo && (
					<div className={classes['center-right']}>
						<div>{additionalInfo}</div>
						{additionalSubInfo && <div className="text-sm-325">{additionalSubInfo}</div>}
					</div>
				)}
			</div>
			{/* Right Part of the button/link */}
			{hasRightArrow && (
				<div className={`${classes['btn-content-right']} d-flex align-items-center `}>
					<ChevronClose color={arrowColor} />
				</div>
			)}
		</div>
	);
};

export const IconNavLink: React.FC<TIconsNavLinkProps> = ({
	isFirst = false,
	isLast = false,
	type = 'link',
	wrapperClasses,
	...rest
}) => {
	const classNames = getClassNames(
		isFirst && classes['is-first-button'],
		isLast && classes['is-last-button'],
		classes['icon-link-wrapper'],
		type === IconNavLinkTypes.BUTTON && classes['icon-btn-wrapper'],
		type === IconNavLinkTypes.STATIC && classes['icon-div-wrapper'],
		wrapperClasses
	);

	const renderContent = () => <IconNavLinkContent {...rest} />;

	switch (type) {
		case IconNavLinkTypes.BUTTON:
			return (
				<div className={classNames} onClick={(rest as TButtonProps).handleClick}>
					{renderContent()}
				</div>
			);
		case IconNavLinkTypes.STATIC:
			return <div className={classNames}>{renderContent()}</div>;
		default:
			return (
				<Link to={(rest as TLinkProps).href} className={classNames}>
					{renderContent()}
				</Link>
			);
	}
};
