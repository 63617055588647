// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import { SVGProps } from 'react';
const SvgShowDont = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 5C5.37 5 0 11.911 0 11.911S5.371 19 12 19s12-7.089 12-7.089S18.624 5 12 5Zm0 12.005a5.44 5.44 0 0 1-2.918-.843 5.065 5.065 0 0 1-1.935-2.246 4.786 4.786 0 0 1-.3-2.892 4.936 4.936 0 0 1 1.438-2.562 5.337 5.337 0 0 1 2.689-1.37 5.494 5.494 0 0 1 3.035.284c.96.38 1.78 1.02 2.357 1.844a4.798 4.798 0 0 1 .486 4.696 4.993 4.993 0 0 1-1.138 1.623 5.274 5.274 0 0 1-1.704 1.085c-.637.251-1.32.38-2.01.38Z"
			fill={props.color || `#111827`}
		/>
		<path
			d="M13.238 11.466a1.151 1.151 0 1 0 0-2.302 1.151 1.151 0 0 0 0 2.302Z"
			fill={props.color || `#111827`}
		/>
		<rect
			x={18.971}
			y={2}
			width={3}
			height={24}
			rx={1.5}
			transform="rotate(45 18.97 2)"
			fill={props.color || `#111827`}
		/>
	</svg>
);
export default SvgShowDont;
